import { CurrencyDollarIcon, DocumentTextIcon, NoSymbolIcon, PaperClipIcon } from '@heroicons/react/24/outline';
import React from 'react';

const ServiceGenFileLocation = ({ expFile,  where}) => {
   const appRoot = process.env.REACT_APP_ROOT_URL;

  if(expFile){
     var expFileUrl = `${appRoot}${where}/${expFile}`;
     return <a href={`${expFileUrl}`} target='_blank'><PaperClipIcon className='h-5'/></a>
  }
  

  // Correctly pass className to the img element
  // return <span className={className} src={expFileUrl} alt="User Avatar" /></span>;
   
};

export default ServiceGenFileLocation;
