import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


// https://localhost:3000/job_gen

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SelectEmployeeCp = ({selected, setSelected, url, size}) => {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([])
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}${url}`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
        if (response.data[0] === 'success') {
          setEmployees(response.data.employees);
        } else {
          toast.info('issues getting employee list');
        }
      } catch (error) {
          if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
          position: "top-center"
          });
          navigate('/login');
          } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
          });
          }
      }
    };

    fetchUsers();
  }, [apiUrl, navigate]);

return (
    <Listbox value={selected} onChange={setSelected} size={size}>
      {({ open }) => (
        <label> Select Employee
          <div className="relative mt-2 ">
            <Listbox.Button className={`relative ${size} cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm`}>
              <span className="block truncate">{selected ? `${selected.first_name} ${selected.last_name}` : 'Select user'}</span>
              <ChevronUpDownIcon className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={`absolute z-10 mt-1 max-h-60 ${size} overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
                {employees.map((employee) => (
                  <Listbox.Option
                    key={employee.id}
                    className={({ active }) => classNames(active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9')}
                    value={employee}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {employee.first_name} {employee.last_name}
                        </span>
                        {selected && (
                          <span className={classNames(active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </label>
      )}
    </Listbox>
  )
}

export default SelectEmployeeCp