import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';
import DateFormat from '../components/DateFormat';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { ArrowLongUpIcon } from '@heroicons/react/24/outline';
import DayOff from '../components/DayOff';
import Alerts from '../components/Alerts';
import SideNav from '../components/SideNav';
import HeadingCp from '../components/HeadingCp';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AlertsView = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/service_crud.php`;
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchServiceCalls = async () => {
      try {
        const response = await axios.get(`${apiUrl}/alert_crud.php?getAllalerts=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });

        const alerts = Array.isArray(response.data) ? response.data : [];
        if (alerts.length === 0) {
          toast.error("No service calls found!", { position: "top-center" });
        }
        setFilterItems(alerts[1]);
      } catch (error) {
        toast.error("Oops minor issue with the data.", { position: "top-center" });
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchServiceCalls();
  }, [apiUrl, navigate]);


 const handleSearchChange = () => {

 }

  return (
    <div className="max-w-[1220px] mx-auto grid grid-cols-1 md:grid-cols-[200px_minmax(540px,_1fr)_260px] gap-x-4">
      <div className="hidden md:block">
        <div className='pt-6 pr-3'>
          <SideNav />
        </div>
      </div>

      <div className=" p-4">
        <div className={'px-4'}>
          <div className="sm:flex sm:items-center">
              <HeadingCp label={'Alerts'} />
          </div>

          <div className=" flow-root">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="sm:flex sm:items-center py-6">
                <div className="flex w-full items-center mt-5 sm:mt-0">
                  {/* <div className="flex-grow">
                      <div className="flex space-x-3 items-center mb-10">
                      <div className="relative rounded-md shadow-sm flex-grow">
                        <input
                          type="text"
                          name="search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                          placeholder="filter by name | department "
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="inline-block min-w-full py-2 align-middle">
                {loading ? (
                  <LoadingSpinner>Loading...</LoadingSpinner>
                ) : (
                  <div className="shadow ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-separate border-spacing-0">
                      <thead className="top-0 z-30 bg-white">
                        <tr>
                          <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                            Message
                          </th>
                          <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                            Pin | Date
                          </th>
                          <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                            location
                          </th>
                          <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(filterItems || []).map((object, objectIdx) => (
                          <tr key={object.id} className={`${objectIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}>
                            
                            <td className={classNames(
                              objectIdx !== filterItems.length - 1 ? 'border-b border-gray-200' : '',
                              'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8')}>
                              {/* <SubMenu label={object.id} idItem={object.id} links={getLinksForJob(object)} /> */}
                              {object.comment}
                            </td>

                            <td className={classNames(
                              objectIdx !== filterItems.length - 1 ? 'border-b border-gray-200' : '',
                              'py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8')}>
                              <div className="flex flex-col">
                                <span className="text-gray-800 text-xs">
                                  {object.pin}
                                </span>
                                <span className="text-gray-400">
                                  <DateFormat date={object.date} />
                                </span>
                              </div>
                            </td>

                            <td className="px-4">
                              <div className="flex flex-col">
                                <span className='text-gray-800 whitespace-nowrap text-sm'>
                                  {object.location ? (
                                    <NavLink to={`/${object.location}`} className={'text-blue-600'}>
                                      <ArrowLongUpIcon className='h-6' />
                                    </NavLink>
                                  ) : ('')}

                                </span>
                                <span className='text-gray-600 text-xs'>
                                  {object.CUSTOMER}
                                </span>
                              </div>
                            </td>
      
                            <td className="px-4">
                              <span className='text-gray-800 text-xs'>
                                {object.status}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden md:block ">
        <div className='pt-4'>
          <div className='border-2'>
            <DayOff />
          </div>
          <br />
          <Alerts />
        </div>
      </div>
    </div>
  );
};

export default AlertsView;
