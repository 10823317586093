import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const GeoAddressLocCp = ({ onAddressFetched }) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [address, setAddress] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentLocation(location);
        },
        (error) => {
          console.error('Error obtaining location', error);
        }
      );
    } else {
      console.error('Geolocation not supported');
    }
  }, []);

  useEffect(() => {
    const fetchAddress = async () => {
      if (currentLocation) {
        try {
          const response = await axios.get(
            `${apiUrl}/tech_crud.php?lat=${currentLocation.lat}&lng=${currentLocation.lng}`
          );
          if (response.data.formatted_address) {
            setAddress(response.data.formatted_address);
            onAddressFetched(response.data.formatted_address);
          } else {
            setAddress('No address found');
            onAddressFetched('No address found');
          }
        } catch (error) {
              if (error.response && error.response.status === 401) {
              navigate('/login');
              } 
          // console.error('Error fetching address', error);
           setAddress('Error fetching address');
           onAddressFetched('Error fetching address');
        }
      }
    };

    fetchAddress();
  }, [currentLocation, onAddressFetched]);

  return (
    <div className="p-4">
      {currentLocation ? (
        <div>
          <h2 className="text-2xl font-semibold">Your Location:</h2>
          <p className="mt-2 text-lg">{address || 'Loading address...'}</p>
        </div>
      ) : (
        <p>Loading location...</p>
      )}
    </div>
  );
};

export default GeoAddressLocCp;
