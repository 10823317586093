// CalendarHeader.js

import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const CalendarHeader = ({
  viewMode,
  selectedWeek,
  selectedDay,
  handlePreviousWeek,
  handleNextWeek,
  handlePreviousDay,
  handleNextDay,
}) => {

  return (
    <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4">
      <div className="relative flex items-center rounded-md bg-white shadow-sm">
        {viewMode === 'week' ? (
          <>

            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500"
              onClick={handlePreviousWeek}
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            <span className="hidden border-gray-300 px-3.5 text-sm font-semibold text-gray-900 md:block">
              {selectedWeek[0]?.toLocaleDateString()} - {selectedWeek[6]?.toLocaleDateString()}
            </span>

            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500"
              onClick={handleNextWeek}
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>

          </>
        ) : (
          <>

            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500"
              onClick={handlePreviousDay}
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>

            <span className="hidden border-gray-300 px-3.5 text-sm font-semibold text-gray-900 md:block">
              {selectedDay?.toLocaleDateString()}
            </span>

            <button
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500"
              onClick={handleNextDay}
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>

          </>
        )}

      </div>
    </header>
  );
};

export default CalendarHeader;
