import React, { useState, Suspense, lazy } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SideNav from '../components/SideNav';
import DayOff from '../components/DayOff';
import Alerts from '../components/Alerts';
import TopTabs from '../components/TopTabs';
import OfficeTimesheetListCp from '../components/OfficeTimesheetListCp';
import TutorialCp from '../components/TutorialCp';
import HeadingCp from '../components/HeadingCp';
import ButtonConfigCp from '../components/ButtonConfigCp';
// import ServicePartListCp from '../components/ServicePartListCp';

const HolidayCmpnt = lazy(() => import('../components/HolidayCmpnt'));
const OvertimeCmpnt = lazy(() => import('../components/OvertimeCmpnt'));
const InventoryCmpnt = lazy(() => import('../components/InventoryCmpnt'));

const SystemPage = () => {
  const { key } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(key || 'holidays');

  const button_config = { name: 'Favorite Buttons', href: 'button_config', current: activeTab === 'button_config' };

  const tabs = [button_config];

  const handleTabClick = (href) => {
    setActiveTab(href);
    navigate(`/${href}`);
  };

  return (
    <div className="max-w-[1220px] mx-auto grid grid-cols-1 md:grid-cols-[200px_minmax(540px,_1fr)_260px] gap-x-4">
      <div className="hidden md:block">
        <div className='pt-6 pr-3'>
          <SideNav />
        </div>
      </div>

      <div className="mt-10 p-4">
        <HeadingCp label={'System Config'} />

        <TopTabs tabs={tabs.map(tab => ({ ...tab, current: tab.href === activeTab }))} onTabClick={handleTabClick} />

        <br/>
        <Suspense fallback={<div>Loading...</div>}>
          {activeTab === 'button_config' && <ButtonConfigCp />}
        </Suspense>
      </div>


      {/* <div className="hidden md:block ">
        <div className='pt-4'>
          <div className='border-2'>
            <DayOff />
          </div>
          <br />
          <Alerts />
        </div>
      </div> */}
    </div>
    
  )
}

export default SystemPage;
