import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBarCP from '../components/SearchBarCP';
import LegendCP from '../components/LegendCP';
import SubMenu from '../components/SubMenu';
import ModalWindow from '../components/ModalWindow';
import Truncate from '../components/Truncate';
import DateFormat from '../components/DateFormat';
import { useUserData } from '../context/UserData';
import LoadingSpinner from '../components/LoadingSpinner';
import HeadingCp from '../components/HeadingCp';
import CurrencyFormat from '../components/CurrencyFormat';
import FileLocation from '../components/FileLocation';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/20/solid';

const ContractsPage = () => {
  const navigate = useNavigate();
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [showVoid, setShowVoid] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModalId, setOpenModalId] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);
  const userData = useUserData();

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/contracts_crud.php`;

  useEffect(() => {
    fetchContracts();
  }, []);

  const fetchContracts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(api, { params: { getList: 'getList' } });
      if (response.data[0] === 'success') {
        setContracts(response.data[1]);
        setFilteredContracts(response.data[1]);
      } else {
        toast.error("Error fetching contracts", { position: 'top-center' });
      }
    } catch (error) { 
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
    } finally {
      setLoading(false);
    }
  };

  const toggleShowVoid = () => {
    setShowVoid(!showVoid);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value === '') {
      setFilteredContracts(contracts);
    } else {
      const filtered = contracts.filter(contract =>
        (contract.job_site_address && contract.job_site_address.toLowerCase().includes(value.toLowerCase())) ||
        (contract.job_site_city && contract.job_site_city.toLowerCase().includes(value.toLowerCase())) ||
        (contract.contract_type && contract.contract_type.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredContracts(filtered);
    }
  };

  const handleSearchClick = async () => {
    setLoading(true);
    const bodyData = {
      status: 'search',
      data: { search: searchTerm }
    };

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data && Array.isArray(response.data)) {
        setContracts(response.data);
        setFilteredContracts(response.data);
      } else {
        setFilteredContracts([]);
        toast.error('No results found for: ' + searchTerm, { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error searching contracts', error);
      toast.error('Error searching contracts', { position: 'top-center' });
    } finally {
      setLoading(false);
    }
  };

  const handleNotes = (contractId) => {
    setOpenModalId(contractId);
  };

  const handleChange = (event, id, user) => {
    const { name, value } = event.target;
    setFilteredContracts(prev => prev.map(contract => {
      if (parseInt(contract.id) === id) {
        const updatedContract = { ...contract, [name]: value };
        updatedContract.employee_name = `${user.first_name} ${user.last_name}`;
        return updatedContract;
      } else {
        return contract;
      }
    }));
  };

  const handleComplete = async (id) => {
    try {
      await axios.post(api, { status: 'submit_complete', id }, {
        headers: { 'Content-Type': 'application/json' }
      });

      toast.info('Contract marked as complete!', { position: 'top-center' });
      // Update the contract's status locally
      setFilteredContracts(prev => prev.map(contract => {
        if (contract.id === id) {
          return { ...contract, status: 'complete' };
        } else {
          return contract;
        }
      }));
    } catch (error) {
      console.error('Error updating contract', error);
      toast.error('Error updating contract', { position: 'top-center' });
    }
  };

  const voidContractSubmit = async (id, currentVoidState) => {
    setLoading(true);
    const newVoidStatus = currentVoidState === 1 ? '' : 1;
    const bodyData = {
      status: 'void',
      data: { contract_id: id, void: newVoidStatus }
    };

    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        setFilteredContracts(prev => prev.map(contract => {
          if (contract.id === id) {
            return { ...contract, status: newVoidStatus };
          }
          return contract;
        }));
        toast.success('Contract status updated!', { position: 'top-center' });
      } else {
        toast.error('Error updating contract', { position: 'top-center' });
      }
    } catch (error) {
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
    } finally {
      setLoading(false);
    }
  };

  const handleFile = (id) => {
    console.log('testing');
  }

  const getLinksForContract = (contract) => [
    { name: 'Edit Contract', click: () => navigate(`/maintenance_contract/edit/${contract.id}`) },
    { name: 'Upload Contract', click: () => handleFile (contract.id) },
    // { name: 'Mark as Complete', click: () => handleComplete(contract.id) },
    { name: contract.status === 1 ? 'Unvoid Contract' : 'Void Contract', click: () => voidContractSubmit(contract.id, contract.status) },
  ];

  return (
    <>
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-semibold text-gray-500 mb-8">
          <HeadingCp label={'Maintenance Dashboard'} />
        </h1>
          <LegendCP color="fill-red-300" label="Void" />
          <LegendCP color="fill-red-600" label="Missing Due(ID)" />
        <br />
        <br />

        <div className="flex space-x-3 items-center mb-10">
          <button
            onClick={() => navigate(`/maintenance_contract/new/new`)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                       rounded-md shadow-sm text-white bg-orange-400 hover:bg-orange-300 focus:outline-none 
                       focus:ring-2 focus:ring-offset-2 focus:ring-black-500">
            <PlusIcon className='h-5' /> New Contract
          </button>    

          <button
            onClick={toggleShowVoid}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                       rounded-md shadow-sm text-white bg-red-600 hover:bg-red-400 focus:outline-none 
                       focus:ring-2 focus:ring-offset-2 focus:ring-black-500">
            {showVoid ? 'Hide Void' : 'Show Void'}
          </button>

          <button
            onClick={() => navigate(`/maintenance_cal`)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium 
                       rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-400 focus:outline-none 
                       focus:ring-2 focus:ring-offset-2 focus:ring-black-500">
            View Scheduler
          </button>

          <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
        </div>

        {loading ? (
          <LoadingSpinner>Loading...</LoadingSpinner>
        ) : (
          <table className="min-w-full divide-y divide-gray-300 bg-gray-200 border rounded-lg">
            <thead>
              <tr className='divide-x divide-gray-400'>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-2">
                  ID/Edit
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Customer
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  File
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Job Site
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Performed | Term | Yr
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Hours
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  System Type
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Current Price
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Notes
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Signed | Exp.
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredContracts.filter(contract => showVoid || contract.status !== 1).map((contract, index) => (
                <tr key={contract.id} className={`${contract.status === 1 ? 'line-through text-red-600' : ''} 
                                                ${index % 2 === 0 && contract.status !== 1 ? 'bg-white' : 'bg-gray-100'} 
                                                divide-x`}>

                  <td className={`${contract.due.length > 0 ? 'text-gray-700' : 'bg-red-600 text-white'} w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium  sm:w-auto sm:max-w-none sm:pl-2`}>
                    <SubMenu label={contract.id} links={getLinksForContract(contract)} idItem={contract.id} />
                  </td>

                  {/* customers / contract type */}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {contract.customer_name}
                    <p className='text-xs text-gray-400'>{contract.contract_type}</p>
                  </td>

                  {/* contract file */}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {/* {contract.start_year} */}
                    <FileLocation expFile={contract.account_file} where={'maintenance_files'}/>
                  </td>

                  {/* job site address / city /state /zip */}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {contract.job_site_address}
                    <p className='text-xs text-gray-400'>
                      {contract.job_site_city} {contract.job_site_state} {contract.job_site_zip}
                    </p>
                  </td>

                  {/* performed / year /term begins */}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <span className='pr-2 text-green-600'>
                      {
                        contract.frequency === 1 ? '(A)' :
                        contract.frequency === 3 ? '(Q)' :
                        contract.frequency === 12 ? '(M)' :
                        contract.frequency === 6 ? '(SA)' : ''
                      }                    
                    </span>
                    <span>
                      <>
                      {!contract.due ? ('') : contract.due.length > 0 ? (
                        contract.due.map((date, index) => (
                          <div key={index}>
                            {date.month}
                          </div>
                        ))
                      ) : ('')}
                      </>
 
                    </span>
                    <span className='pr-1'>
                      {contract.start_year}
                    </span>
                    <span>
                      {contract.term_begins}
                    </span>
                  </td>

                  {/* hours */}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {contract.hours} Hrs
                  </td>

                  {/* system type */}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {contract.system_type}
                  </td>

                  {/* current price */}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {contract.current_price ? (
                      <CurrencyFormat value={contract.current_price} />
                    ) : ('')}
                  </td>

                  {/* Notes */}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <p>
                      {contract.notes ? (
                        <span className='hover:cursor-pointer' onClick={() => handleNotes (contract.id)}>
                          <PencilSquareIcon className='h-5 text-blue-500'/>
                        </span>
                      ) : ('')}
                    </p>
                    {openModalId === contract.id && (
                      <ModalWindow
                        job={
                          /* Implement the modal content for editing the contract */
                          <div>{contract.notes}</div>
                        }
                        closeNotes={() => setOpenModalId(null)}
                      />
                    )}                    
                  </td>

                  {/* experation date /signed date */}
                  <td className="px-3 py-4 text-sm text-gray-500">
                    <p>{contract.signed_date}</p>
                    <p className='text-xs text-gray-400'>{contract.exp_date_contract === 'auto renew' ? 'Auto-renew' : <DateFormat date={contract.exp_date_contract} />}</p>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default ContractsPage;
