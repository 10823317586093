import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const COTtopTabs = ({ tabs }) => {
  // Defensive check to ensure tabs is not undefined or empty
  if (!tabs || !Array.isArray(tabs) || tabs.length === 0) {
    return <div>No tabs available</div>;
  }

  // Find the current tab to set the default value for the select element
  const currentTab = tabs.find((tab) => tab.current);

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={currentTab ? currentTab.name : ''}
              onChange={(e) => {
                const selectedTab = tabs.find(tab => tab.name === e.target.value);
                if (selectedTab && selectedTab.onClick) {
                  selectedTab.onClick();
                }
              }}>
              {tabs.map((tab) =>
                tab && tab.name ? (
                  <option key={tab.name}>{tab.name}</option>
                ) : (
                  <option key={`invalid-${Math.random()}`}>Invalid tab</option>
                )
              )}
            </select>
      </div>

    <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) =>
              tab && tab.name ? (
                <button
                  key={tab.name}
                  onClick={tab.onClick}
                  className={classNames(
                    tab.current
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                    'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                  )}
                  aria-current={tab.current ? 'page' : undefined}>
                  {tab.name}
                </button>
              ) : (
                <span key={`invalid-${Math.random()}`}>Invalid tab</span>
              )
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default COTtopTabs;
