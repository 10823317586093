// PathsCp.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PathsCp = ({ value, onChange }) => {
  const [routes, setRoutes] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchPaths = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/config_system_crud.php?pathsList=true`,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
          }
        );
        if (response.data[0] === 'success') {
          setRoutes(response.data[1]);
        }
      } catch (error) {
        console.error('Error fetching paths:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPaths();
  }, [apiUrl]);

  if (loading) {
    return <div className="text-gray-500">Loading paths...</div>;
  }

  return (
    <select
      value={value || ''}
      onChange={onChange}
      className="block w-full mt-1 rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
    >
      <option value="">Select a page...</option>
      {routes.map((route, index) => (
        <option key={index} value={route.id}>
          {route.label}
        </option>
      ))}
    </select>
  );
};

export default PathsCp;
