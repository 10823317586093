import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const UserContext = createContext(); 

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
     const apiUrl = process.env.REACT_APP_API_URL;

        // if (userData) return; // If userData is  already set, skip fetching
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/users_crud.php?getEmployee=1`, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
                });
                    
                if (response.data.location === "field") {
                    setUserData(response.data);
                    navigate('/t');
                    // toast.error("Tech Section", { position: "top-center" });
                } else {
                    setUserData(response.data);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                    toast.error("Session expired. Please login again.", { position: "top-center" });
                }
            }
        };
        fetchUserData();
    }, [navigate]);

    return (
        <UserContext.Provider value={userData}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserData = () => useContext(UserContext);
