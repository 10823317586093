import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import DateFormat from '../components/DateFormat';
import CurrencyFormat from '../components/CurrencyFormat';

const parseDateString = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day); // Months are zero-based
};

const formatDateHeader = (dateString) => {
  const date = parseDateString(dateString);
  const day = date.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit' });
  const weekday = date.toLocaleDateString('en-US', { weekday: 'short' });
  return (
    <>
      <div>{day}</div>
      <div>{weekday}</div>
    </>
  );
};

const getDateRequestColor = (date_request) => {
  switch (date_request) {
    case 'in':
      return 'bg-green-300';
    case 'No Pay':
      return 'bg-red-400';
    case 'Vacation Day':
      return 'bg-blue-300';
    case 'Half Vacation Day':
      return 'bg-orange-300';
    case 'Sick Day':
      return 'bg-indigo-300';
    case 'Excused Pay':
      return 'bg-red-300';
    case 'Partial Hour':
      return 'bg-red-300';
    default:
      return '';
  }
};

const PayrollPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [fetchItems, setFetchItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [weekDays, setWeekDays] = useState([]);
  const [week, setWeek] = useState('active_week');
  const [payperiodMonth, setPayPeriodMonth] = useState(null);
  const [payperiod, setPayPeriod] = useState(null);
  const [holiday, setHoliday] = useState(null);

  const handleWeek = (e, date) => {
    e.preventDefault();
    setWeek(date);
  };

  useEffect(() => {
    setLoading(true);
    fetchEmployeePayroll(week);
  }, [week]);

  const fetchEmployeePayroll = async (week) => {
    try {
      const response = await axios.get(`${apiUrl}/payroll_crud.php?payroll=${week}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
      });
      const payroll = Array.isArray(response.data.employees) ? response.data.employees : [];
      if (payroll.length === 0) {
        toast.error('No payroll report found!', { position: 'top-center' });
      }
      setFilterItems(payroll || []);
      setFetchItems(payroll || []);
      setWeekDays(response.data.pay_period.week || []); // Use week1 from backend
      setPayPeriodMonth(response.data.pay_periods_month);
      setPayPeriod(response.data.pay_period.payPeriod);
      setHoliday(response.data.holiday);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', { position: 'top-center' });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      } else {
        toast.error('An unexpected error occurred.', {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to filter out Saturday and Sunday
const filterWeekDays = (days) => {
  return days.filter((day) => {
    const [year, month, date] = day.split('-').map(Number);
    const localDate = new Date(year, month - 1, date); // Months are zero-based
    const dayOfWeek = localDate.getDay();
    return dayOfWeek !== 0 && dayOfWeek !== 6; // Exclude Sunday (0) and Saturday (6)
  });
};

  // Get filtered weekdays
  const filteredWeekDays = filterWeekDays(weekDays);
  const filteredWeekDaysHeader = filteredWeekDays;
  

  const getEmployeeDateRequest = (days, date) => {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    const day = days.find((d) => d.date_out === formattedDate);
    return day
      ? day.date_request === 'Partial Hour'
        ? day.date_request + ' ' + day.partial_hours
        : day.date_request
      : 'NS';
  };

  const getEmployeeVacation = (vacationDays, date) => {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    return vacationDays.includes(formattedDate) ? 'Vacation Day' : 'NS';
  };

  return (
    <div className="mx-auto max-w-6xl sm:px-4 lg:px-6 py-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 py-4">Payroll Report</h1>
        </div>
      </div>
      
      {payperiodMonth ? (
        <>
          {payperiodMonth.map((pay_period_week) => (
            <span key={pay_period_week} className="px-2 mt-2">
              <button
                className={`px-4 py-1 text-white rounded-md hover:bg-blue-600 ${
                  pay_period_week === payperiod ? 'bg-green-500' : 'bg-blue-500'
                } `}
                onClick={(e) => handleWeek(e, pay_period_week)}
              >
                <DateFormat date={pay_period_week} />
              </button>
            </span>
          ))}
        </>
      ) : (
        <LoadingSpinner>Loading...</LoadingSpinner>
      )}

      <div className="mt-8 flow-root">
        <div className="-mx-1 -my-1 sm:-mx-2 lg:-mx-3">
          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <LoadingSpinner>Loading...</LoadingSpinner>
            ) : (
              <div className="shadow ring-1 ring-black ring-opacity-5 max-w-[1153px] mx-auto overflow-auto">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead className="top-0 z-30 bg-white">
                    <tr>
                      <th className="border-b border-gray-300 bg-white bg-opacity-75 py-1.5 pl-2 pr-2 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-3 lg:pl-4">
                        Employee Name
                      </th>
                      <th className="hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        TH
                      </th>
                      <th className="hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        OT
                      </th>
                      <th className="hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        RH
                      </th>
                      <th className="hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        VH
                      </th>
                      <th className="hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        HH
                      </th>
                      {filteredWeekDaysHeader.map((date) => (
                        <th
                          key={date}
                          className="border-b border-gray-300 bg-white bg-opacity-75 py-1.5 pl-2 pr-2 backdrop-blur backdrop-filter sm:pr-3 lg:pr-4"
                        >
                          {formatDateHeader(date)}
                        </th>
                      ))}
                      <th className="hidden border-b border-gray-300 bg-white bg-opacity-75 px-2 py-1.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        Expense | Notes
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {filterItems.map((object, objectIdx) => (
                      <tr
                        key={object.id}
                        className={`${
                          objectIdx % 2 === 0 ? 'bg-white' : 'bg-gray-100'
                        } divide-x divide-y divide-gray-300 text-sm`}
                      >
                        <td className="px-2 py-1">
                          <span className="text-blue-800">
                            <NavLink to={`/profile/${object.id}`}>{object.employee_name}</NavLink>
                          </span>
                          <br />
                          <span className="text-gray-500 text-xs">
                            ({object.id}) {object.department}
                          </span>
                        </td>

                        <td
                          className={`px-2 py-1 ${
                            parseFloat(object.RH) + parseFloat(object.VH) > 80
                              ? 'bg-red-500'
                              : parseFloat(object.RH) + parseFloat(object.VH) < 80
                              ? 'bg-[#fed7aa]'
                              : 'bg-[#bfdbfe]'
                          }`}
                        >
                          TH {parseFloat(object.RH) + parseFloat(object.VH)}
                        </td>

                        <td className={`px-2 py-1 ${object.OT > 0 ? 'bg-purple-300' : ''}`}>
                          OT {parseFloat(object.OT)}
                        </td>

                        <td className="px-2 py-1">RH {parseFloat(object.RH)}</td>

                        <td className="px-2 py-1">VH {parseFloat(object.VH)}</td>

                        <td className="px-2 py-1">HH 0</td>

                        {filteredWeekDays.map((date) => (
                          <td
                            key={date}
                            className={`px-2 py-1 ${
                              holiday === date ? 'bg-yellow-300' : ''
                            } ${getDateRequestColor(
                              getEmployeeDateRequest(object.days, date)
                            )} ${getDateRequestColor(
                              getEmployeeVacation(object.vacation_days, date)
                            )} text-center`}
                          >
                            {holiday === date
                              ? 'Holiday'
                              : getEmployeeDateRequest(object.days, date) !== 'NS'
                              ? getEmployeeDateRequest(object.days, date)
                              : getEmployeeVacation(object.vacation_days, date)}
                          </td>
                        ))}

                        <td>
                          {object.o_f_type === 'Field' ? (
                            object.expense.expense_amount && (
                              <span className="text-purple-700 ml-4">
                                <CurrencyFormat value={object.expense.expense_amount} />
                              </span>
                            )
                          ) : (
                            object.officeExpense && (
                              <>
                                <span className="text-purple-700 ml-2">
                                  <CurrencyFormat value={object.officeExpense} />
                                </span>
                                <span>{object.notes}</span>
                              </>
                            )
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollPage;
