import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';
import Modal from '../components/Modal';
import ShortDateFormat from '../components/ShortDateFormat';
import PhoneNumber from '../components/PhoneNumber';

const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const TechServiceDetail = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [selectDeliveryId, setSelectDeliveryId] = useState(null);
  const [file, setFile] = useState(null);
  const [serviceCalls, setServiceCalls] = useState([]);

  
  useEffect(() => {
    setLoading(true);
    const fetchServiceCalls = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?getTechList=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        if(response.data[0] === 'success')
        {
          const logs = Array.isArray(response.data[1]) ? response.data[1] : [];
          setServiceCalls(logs);
        }
        else{
          toast.error(response.data.error, { position: "top-center" });
        }
        
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchServiceCalls();
  }, [apiUrl, navigate]);

  const handleActivation = async(id, status) => {
    const bodyData = {
      status: "activateCall",
      id: id
    };
    if(status === 'ACTIVATED')
    {
      navigate(`/t/tservdetail/${id}`);
    }
    else
    {
      try {  
        const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' },
        });    
        if (response.data[0] === 'success') 
        {
            setServiceCalls((prevCalls) =>
            prevCalls.map((log) => log.id === id  ? { ...log, STATUS: 'ACTIVE' }  : log ));
            toast.success('Service call activated', {
              position: 'top-center',
            });
            navigate(`/t/tservdetail/${id}`);
        } 
        else 
        {
          toast.info("Failed to activate call.", { position: "top-center" });
        }
      } 
      catch (error) 
      {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center",
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
            position: "top-center",
          });
        }
      } 
      finally 
      {
        setLoading(false);
      }      
    }

  }

 return (
    <>
    <HeadingCp label={"Service Queue"}/>
    <ul role="list" className="divide-y divide-gray-100">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {serviceCalls.map((log) => (
          <div
            key={log.id}
            onClick={() => handleActivation(log.id, log.STATUS)}
            className={classNames(
              "relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400",
              log.STATUS !== 'ACTIVATED' ? 'opacity-60' : ''
            )}
          >
            <div className="flex-shrink-0">
              <span
                className={`rounded ${
                  log.STATUS === 'ACTIVATED' ? 'bg-blue-600' : 'bg-orange-600'
                } px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              >
                {log.STATUS === 'ACTIVATED' ? 'Activated' : 'Activate'}
              </span>
              <p>{log.TIME ? log.TIME : ''}</p>
              <p>{log.SCHEDULE_DATE ? log.SCHEDULE_DATE : <ShortDateFormat date={log.DATE} />}</p>
            </div>
            <div className="min-w-0 flex-1">
                <span aria-hidden="true" className="absolute inset-0" />
                <p className="text-sm font-medium text-green-700">{log.CUSTOMER} | Log#{log.id}</p>
                <p className="truncate text-sm text-gray-500">{log.ADDRESS}</p>
                <p className="truncate text-xs text-gray-500">Called by: {log.CALLED_BY} Tel: {log.PHONE ? <PhoneNumber number={log.PHONE}/> : ''}</p>
                <p className="truncate text-xs text-yellow-700">Notes: {log.NOTES}</p>
            </div>
          </div>
        ))}
      </div>
    </ul>
    </>
  )
}

export default TechServiceDetail