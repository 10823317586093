// FileUploadModal.jsx

import React from 'react';

  // const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  // const [isModalFileOpen, setIsModalFileOpen] = useState(false);

 {/*this is to handle the file change */}
  // const handleFileChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   if (!selectedFile) {
  //     toast.error("No file selected.", { position: 'top-center' });
  //     setFile(null);
  //     return;
  //   }

  //   const validFileTypes = ['application/pdf'];
  //   const maxSize = 2 * 1024 * 1024; // 2MB in bytes

  //   if (!validFileTypes.includes(selectedFile.type)) {
  //     toast.error("Invalid file type. Only PDF is allowed.", { position: 'top-center' });
  //     setFile(null);
  //     return;
  //   }

  //   if (selectedFile.size > maxSize) {
  //     toast.error("File size exceeds 2MB.", { position: 'top-center' });
  //     setFile(null);
  //     return;
  //   }

  //   setFile(selectedFile);
  // };

// ------------------------------------------------------------------------------------

  {/* this code below is for uploading the file API*/}
  // const handleUploadFile = async (e) => {
  //   e.preventDefault();
  //   if (!file) {
  //     toast.error("Please select a file before uploading.", { position: 'top-center' });
  //     return;
  //   }
    
  //   const formData = new FormData();
  //   formData.append('status', 'submit_accounting_file');
  //   formData.append('id', selectAccountingFile); // Include the accounting vendor ID
  //   formData.append('file', file);

  //   //console.log("FormData before upload:", formData.get('status'), formData.get('id'), formData.get('file')); // Debugging

  //   try {
  //     const response = await axios.post(`${apiUrl}/accounting_file.php`, formData, {
  //       headers: { 'Content-Type': 'multipart/form-data' }
  //     });

  //     //console.log("Upload response:", response.data); // Debugging

  //     if (response.data.success) {
  //       toast.info('File uploaded successfully!', { position: 'top-center' });

  //       // Update the state with the new file information
  //       setFilteredAccountingVendor(prev => 
  //           prev.map(accounting =>
  //               accounting.id === selectAccountingFile ? 
  //               { ...accounting, account_file: response.data.accounting.file } : accounting
  //           )
  //       );

  //       setFile(null);
  //       setIsModalFileOpen(false);
  //     } else {
  //       toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
  //     }
  //   } catch (error) {
  //     toast.error("An error occurred during file upload.", { position: 'top-center' });
  //     //console.error('Error uploading file', error);
  //   }
  // };

const FileUploadModal = ({
  isOpen,
  onClose,
  onSubmit,
  onFileChange,
  accept = 'application/pdf',
  title = 'Upload File',
  label = 'Select a file (Max size 2MB)',
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="bg-white p-8 rounded-md shadow-md min-w-[500px]">
        <h2 className="text-xl font-semibold mb-4">{title}</h2>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              {label}
            </label>
            <input
              type="file"
              accept={accept}
              onChange={onFileChange}
              required
              className="mt-2"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              Upload
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FileUploadModal;
