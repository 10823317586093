// ButtonConfigCp.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ModalWindow from '../components/ModalWindow';
import SubmitButton from '../components/SubmitButton';
import LoadingSpinner from '../components/LoadingSpinner';
import { NavLink, useNavigate } from 'react-router-dom';
import PathsCp from '../components/PathsCp';

const ButtonConfigCp = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [buttonConfig, setButtonConfig] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newButtonConfig, setNewButtonConfig] = useState({
    id: '',
    button_1_link: null,
    button_2_link: null,
    button_3_link: null,
    button_4_link: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingConfig, setEditingConfig] = useState(null);
  const navigate = useNavigate();

  // Fetch the button configuration
  useEffect(() => {
    fetchButtonConfig(apiUrl, navigate);
  }, [apiUrl, navigate]);

  const fetchButtonConfig = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/config_system_crud.php`, {
        params: { getButtons: true },
      });
      if(response.data[0] === 'success')
      {
        setButtonConfig(response.data[1]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error(
          "Oops!! you don't have access to this area. Speak to the CFO.",
          {
            position: 'top-center',
          }
        );
      } else {
        toast.error('An error occurred.', {
          position: 'top-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // Fetch routes
  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/config_system_crud.php?pathsList=true`,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
          }
        );
        if (response.data[0] === 'success') {
          setRoutes(response.data[1]);
        }
      } catch (error) {
        console.error('Error fetching routes:', error);
      }
    };

    fetchRoutes();
  }, [apiUrl]);

    // Handle path selection changes
  const handlePathChange = (e, num) => {
    const selectedId = e.target.value;
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    const fieldName = `button_${num}_link`;

    // Convert selectedId to number if route.id is a number
    const selectedRoute = routes.find(
      (route) => route.id === parseInt(selectedId, 10)
    );

    if (selectedId && selectedRoute) {
      const buttonData = {
        id: selectedId,
        path: selectedRoute.path,
        label: selectedLabel,
      };

      if (editingConfig) {
        setEditingConfig((prev) => ({ ...prev, [fieldName]: buttonData }));
      } else {
        setNewButtonConfig((prev) => ({ ...prev, [fieldName]: buttonData }));
      }
    } else {
      // Handle the case where selectedRoute is undefined
      toast.error('Selected route not found.', { position: 'top-center' });
    }
  };

  // Submit new configuration
  const handleNewConfigSubmit = async (e) => {
    e.preventDefault();
    const button_1_link = newButtonConfig.button_1_link ? parseInt(newButtonConfig.button_1_link.id) : null;
    const button_2_link = newButtonConfig.button_2_link ? parseInt(newButtonConfig.button_2_link.id) : null;
    const button_3_link = newButtonConfig.button_3_link ? parseInt(newButtonConfig.button_3_link.id) : null;
    const button_4_link = newButtonConfig.button_4_link ? parseInt(newButtonConfig.button_4_link.id) : null;
     const bodyData = {
      status: 'addButtons',
      id: null,
      data: { 
        button_1_link: button_1_link,
        button_2_link: button_2_link,
        button_3_link: button_3_link,
        button_4_link: button_4_link
       }
    };
    
    try {
      const response = await axios.post(`${apiUrl}/config_system_crud.php`, bodyData, {
       headers: { 'Content-Type': 'application/json' },
      });
      if (response.data.status === 'success') {
        fetchButtonConfig();
        toast.success('Configuration added successfully!', {
          position: 'top-center',
        });
        setIsModalOpen(false);
        setNewButtonConfig({
          id: '',
          button_1_link: null,
          button_2_link: null,
          button_3_link: null,
          button_4_link: null,
        });
      } else {
        toast.error('Failed to add configuration', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An error occurred', {
        position: 'top-center',
      });
    }
  };

  // Edit existing configuration
  const handleEditConfig = (config) => {
    setEditingConfig(config);
    setIsModalOpen(true);
  };

  // Submit edited configuration
  const handleEditConfigSubmit = async (e) => {
    e.preventDefault();
    const button_1_link = newButtonConfig.button_1_link ? parseInt(newButtonConfig.button_1_link.id) : null;
    const button_2_link = newButtonConfig.button_2_link ? parseInt(newButtonConfig.button_2_link.id) : null;
    const button_3_link = newButtonConfig.button_3_link ? parseInt(newButtonConfig.button_3_link.id) : null;
    const button_4_link = newButtonConfig.button_4_link ? parseInt(newButtonConfig.button_4_link.id) : null;
     const bodyData = {
      status: 'addButtons',
      id: editingConfig.id,
      data: { 
        button_1_link: button_1_link,
        button_2_link: button_2_link,
        button_3_link: button_3_link,
        button_4_link: button_4_link
       }
    };
    try {
      const response = await axios.post(`${apiUrl}/config_system_crud.php`, bodyData, {
         headers: { 'Content-Type': 'application/json' },
       });
      if (response.data.status === 'success') {
        setButtonConfig(response.data.config);
        toast.success('Configuration updated successfully!', {
          position: 'top-center',
        });
        setIsModalOpen(false);
        setEditingConfig(null);
        fetchButtonConfig();
      } else {
        toast.error('Error updating configuration.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('Error updating configuration.', {
        position: 'top-center',
      });
    }
  };

  // Delete configuration
  const handleDeleteConfig = async (id) => {
      try {
        const response = await axios.post(`${apiUrl}/config_system_crud.php`, {
          status: 'deleteButtons',
          id: id,
        });
        if (response.data.status === 'success') {
          setButtonConfig(false);
          toast.success('Configuration deleted successfully!', {
            position: 'top-center',
          });
        } else {
          toast.error('Error deleting configuration.', {
            position: 'top-center',
          });
        }
      } catch (error) {
        console.error('Error deleting configuration:', error);
        toast.error('Error deleting configuration.', {
          position: 'top-center',
        });
      }
  };

  return (
    <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 py-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 py-4">
            Buttons Configuration
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => {
              if (buttonConfig) {
                handleEditConfig(buttonConfig);
              } else {
                setIsModalOpen(true);
              }
            }}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
          >
            {buttonConfig ? 'Edit Configuration' : 'Add Configuration'}
          </button>
        </div>
      </div>
      {loading ? (
        <LoadingSpinner>Loading...</LoadingSpinner>
        ) : buttonConfig ? (
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow ring-1 ring-black ring-opacity-5">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead className="bg-white">
                    <tr>
                      <th className="border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Buttons
                      </th>
                      <th className="border-b border-gray-300 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="flex flex-col">
                          {[1, 2, 3, 4].map((num) => {
                            const button = buttonConfig[`button_${num}_link`];
                            if (button && button.id) {
                              const route = routes.find(
                                (route) => route.id === button.id
                              );
                              if (route) {
                                return (
                                  <NavLink
                                    key={num}
                                    to={route.path}
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    {route.label}
                                  </NavLink>
                                );
                              }
                            }
                            return null;
                          })}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <button
                          onClick={() => handleEditConfig(buttonConfig)}
                          className="text-blue-600 hover:text-blue-900 mr-4"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDeleteConfig(buttonConfig.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>No configuration found.</div>
      )}

      {/* Modal for adding/editing configurations */}
      {isModalOpen && (
        <ModalWindow
          job={
            <div className="flex items-center justify-center py-5">
              <form
                onSubmit={
                  editingConfig ? handleEditConfigSubmit : handleNewConfigSubmit
                }
                className="w-full rounded-lg"
              >
                <div className="space-y-12">
                  <h2 className="text-lg font-semibold text-gray-900">
                    {editingConfig
                      ? 'Edit Buttons Configuration'
                      : 'New Buttons Configuration'}
                  </h2>

                  {/* Buttons */}
                  {[1, 2, 3, 4].map((num) => (
                    <div key={num} className="sm:col-span-2">
                      <label
                        htmlFor={`button_${num}_link`}
                        className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                      >
                        Button {num} Page
                      </label>

                      <div className="mt-2">
                        <PathsCp
                          routes={routes}
                          value={
                            editingConfig
                              ? editingConfig[`button_${num}_link`]?.id || ''
                              : newButtonConfig[`button_${num}_link`]?.id || ''
                          }
                          onChange={(e) => handlePathChange(e, num)}
                        />
                      </div>
                    </div>
                  ))}

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => {
                        setIsModalOpen(false);
                        setEditingConfig(null);
                      }}
                    >
                      Close
                    </button>
                    <SubmitButton>Submit</SubmitButton>
                  </div>
                </div>
              </form>
            </div>
          }
          closeNotes={() => {
            setIsModalOpen(false);
            setEditingConfig(null);
          }}
        />
      )}
    </div>
  );
};

export default ButtonConfigCp;