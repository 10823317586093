// Assuming these image imports are available
import holidaysImage from '../assets/images/holidays.gif';
import independenceDayImage from '../assets/images/independence_day.gif';
import thanksgiving1 from '../assets/images/thanksgiving1.gif';
import thanksgiving2 from '../assets/images/thanksgiving2.gif';
import laborday from '../assets/images/laborday.gif';
import memorialDay from '../assets/images/memorial_day.gif';

/**
 * Utility function to get holiday background image styles.
 * @param {Object} day - The day object containing the date.
 * @param {Array} holidays - Array of holiday objects with `date` and `name` properties.
 * @returns {Object} - The style object for the holiday background image.
 */
export const getHolidayBackgroundImage = (day, holidays) => {
  const holiday = holidays?.find((holiday) => holiday.date === day.date);
  
  const backgroundStyles = {
    backgroundSize: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  if (day.date === `${new Date().getFullYear()}-07-04`) {
    return { ...backgroundStyles, backgroundImage: `url(${independenceDayImage})` };
  } else if (day.date === `${new Date().getFullYear()}-12-25`) {
    return { ...backgroundStyles, backgroundImage: `url(${holidaysImage})` };
  } else if (holiday) {
    switch (holiday.name) {
      case 'Thanksgiving Day':
        return { ...backgroundStyles, backgroundImage: `url(${thanksgiving1})` };
      case 'Day after Thanksgiving Day':
        return { ...backgroundStyles, backgroundImage: `url(${thanksgiving2})` };
      case 'Labor Day':
        return { ...backgroundStyles, backgroundImage: `url(${laborday})` };
      case 'Memorial Day':
        return { ...backgroundStyles, backgroundImage: `url(${memorialDay})` };
      default:
        return { backgroundColor: 'bg-green-400' }; // Fallback for unknown holidays
    }
  }

  return {}; // Return default styles if no holiday matches
};
