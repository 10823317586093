import { ArrowUpCircleIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import React from 'react';

const FileLocationCp = ({ FileName, where, notFoundButton, className}) => {
   const appRoot = process.env.REACT_APP_ROOT_URL;

  if(FileName){
     var expFileUrl = `${appRoot}/${where}/${FileName}`;
     return <a href={`${expFileUrl}`} target='_blank'><DocumentTextIcon className='h-5'/></a>
  } else {

     return (notFoundButton? notFoundButton : <ArrowUpCircleIcon className="h-5" /> )
  }

  // Correctly pass className to the img element
  // return <span className={className} src={expFileUrl} alt="User Avatar" /></span>;
   
};

export default FileLocationCp;
