import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/20/solid';
import axios from 'axios'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateFormat from '../components/DateFormat';
import { useUserData } from '../context/UserData';
import { dateToUnixTimestamp } from '../utils/dateToUnixTimestamp';
import { UtilConvertDateTodayDate } from '../utils/UtilConvertDateTodayDate';
import TimesheetExpenseCp from '../components/TimesheetExpenseCp';
import LoadingSpinner from '../components/LoadingSpinner';
import { getTodaysDateUtil } from '../utils/DateFormatTodayUtil';
import { findAllByDisplayValue } from '@testing-library/react';

const TechWkTimesheet = () => {
  const navigate = useNavigate();

  const [monthWeek, setMonthWeek] = useState('active_month');

  const [expense, setExpense] = useState(false);
  const [expenseData, setExpenseData] = useState([]);
  const [timesheet, setTimesheet] = useState({
    t_id: '',
    employee_id: '',
    comment: '',
    expense: '',
    expense_approval: '',
    overtime_approval: '',
    mod_by: '',
    pay_period: ''
  });
  const [showAppoved, setShowApproved] = useState(true);
  const [holidays, setHolidays] = useState([]);
  const [isHoliday, setIsholiday] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [openModalId, setOpenModalId] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);
  const [payperiod, setPayPeriod] = useState(null);
  const [week, setWeek] = useState([]);
  const userData = useUserData();
  const [access, setAccess]= useState(false);
  const [bodyData, setBodyData] = useState({
    status: 'submit_days',
    current_date: '',
    data: {}
  });

  const [payperiodMonth, setPayPeriodMonth] = useState(null);//encapsulate

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/tech_crud.php`;

  const handleWeek = (weekType) => {
    if (weekType === 'week1') {
      setWeek(payperiod?.week1 || []);
    } else {
      setWeek(payperiod?.week2 || []);
    }
  };

  useEffect(() => {
    fetchData(week, monthWeek);
  }, [week, monthWeek]);

  const fetchData = async (week, monthWeek) => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/tech_crud.php?timesheet=${week}&&monthweek=${monthWeek}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
      const timesheet_logs = Array.isArray(response.data.timesheet) ? response.data.timesheet : [];
      setBodyData(prev => ({ ...prev, current_date: week }));
      setIsholiday(dateToUnixTimestamp(response.data.holiday) === week);
      setPayPeriod(response.data.pay_period);
      // Array.isArray(response.data.pay_period.week) && setWeek(response.data.pay_period.week);
      setExpenseData(response.data.expense);
      setPayPeriodMonth(response.data[0]);
      setAccess(response.data.access)
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (log_id, e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(`${apiUrl}/tech_crud.php`, {
        status: 'delete_timesheet_log',
        data: { log_id: log_id },
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success("Time entry deleted successfully!", {
          position: "top-center"
        });
        // Update the week state by removing the deleted log
        setWeek((prevWeek) =>
          prevWeek.map((item) =>
            item.logs
              ? {
                  ...item,
                  logs: item.logs.filter((log) => log.id !== log_id),
                }
              : item
          )
        );
      } else {
        toast.error(response.data.message || "Failed to delete holiday.", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center"
      });
    }
  }

  const handleDeleteExpense = async (t_id) => {
    // e.preventDefault();
    
      try {
        const response = await axios.post(`${apiUrl}/tech_crud.php`, {
          status: 'delete_expense',
          data: { timesheet_id: t_id },
          headers: { 'Content-Type': 'application/json' }
        });
        if (response.data.success) {
          toast.success("Time entry deleted successfully!", {
            position: "top-center"
          });
          setExpenseData([]);
        } else {
          toast.error(response.data.message || "Failed to delete holiday.", {
            position: "top-center"
          });
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.", {
          position: "top-center"
        });
      }
     }

  const handleExpense = () => {
    setExpense(true)
  }
  
  const handleExpenseSuccess = (newExpense) => {
    setExpense(false);
    setExpenseData(newExpense)
  }

  const handleMonthWeek = (e, date) => {
    e.preventDefault();
    setWeek([])
    const timeStampDate = dateToUnixTimestamp(date);
    setMonthWeek(timeStampDate);
  };  


  return (
    <>
      <div>
        {payperiodMonth ? (
          <>
            {payperiodMonth.map((pay_period_week, index) => {
              return (
                <span key={pay_period_week} className="px-2 mt-2">
                  <button
                    className={`px-4 py-1 text-white rounded-md hover:bg-blue-600 ${pay_period_week === payperiod.nextPayPeriod ? 'bg-green-500' : 'bg-blue-500'} `}
                    onClick={(e) => handleMonthWeek(e, pay_period_week)}>
                    <DateFormat date={pay_period_week} />
                  </button>
                </span>
              );
            })}
          </>
        ) : (
          <LoadingSpinner children={'Loading...'}/>
        )}
  <br/>
  <br/>
        Pay period: <DateFormat date={payperiod?.payPeriod} />
        {payperiod ? (
          <>
          <br/>
          <br/>
            {!expense && expenseData && expenseData.id ? (
                <button type="button"
                  onClick={() => handleDeleteExpense(expenseData.id)}
                  className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Delete Expense
                </button>
             ) : (
              <>
                <button type="button"
                  onClick={() => handleExpense()}
                  className="rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Submit Expense
                </button>
              </>
              )}

            {expenseData && expenseData.id ? (
              <>
              <div className="p-2 bg-green-100 rounded-sm text-sm">
                <p>Expense submitted:</p>
                <p>Amount: ${expenseData.expense_amount}</p>
                <p>Comment: {expenseData.expense_comment}</p>
                <p>File:(saved)</p>
              </div>
              </>
            ) : (<br /> )}
{/* 
            <br />
            <button type="button"
              onClick={() => handleWeek('week1')}
              className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Week 1
            </button>

            <button type="button"
              onClick={() => handleWeek('week2')}
              className="rounded-md bg-indigo-600 px-2.5 py-1.5 ml-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Week 2
            </button> */}
          </>
        ) :
          (
            <div>loading...</div>
          )}
      </div>

      {Array.isArray(payperiod?.week) && payperiod?.week.length > 0 ? (
        <ul className="divide-y divide-gray-200">
          {payperiod?.week.map((item) => (
            <li key={item.date} className="py-4">
              <div className="flex items-center justify-between w-full">
              {!access && item.date <= getTodaysDateUtil() ? (
                <div className="flex items-center">
                  <PlusCircleIcon className="h-5 mr-2 text-blue-600" />
                  <NavLink to={`/t/twkl/${dateToUnixTimestamp(item.date)}/${monthWeek}`}>
                    {UtilConvertDateTodayDate(item.date)}
                  </NavLink>
                </div>
              ) : (
                <span>{UtilConvertDateTodayDate(item.date)}</span>
              )}
              </div>

              {item.logs && item.logs.length > 0 && (
                <div className="mt-2">
                  {item.logs.map((log) => (
                    <div key={log.id} className="flex items-center justify-between gap-x-6 py-1">
                      <div className="min-w-0">
                        <div className="flex items-start gap-x-3">
                          <p className="mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">
                            {UtilConvertDateTodayDate(log.date)}
                          </p>
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            {log.address}
                          </p>
                        </div>
                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                          <p className="whitespace-nowrap">
                            {log.time_type_input}
                          </p>
                          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                            <circle cx={1} cy={1} r={1} />
                          </svg>
                          <p className="whitespace-nowrap">
                            {log.numberid}
                          </p>
                          <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                            <circle cx={1} cy={1} r={1} />
                          </svg>
                          <p className="truncate">
                            R: {log.reg_hrs} | O: {log.over_hrs}
                          </p>
                        </div>
                      </div>
                      {!access && (
                      <TrashIcon onClick={(e) => handleDelete(log.id, e)} className='h-5 text-red-700 hover:cursor-pointer'/>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <div className='py-4'>No data for selected week</div>
      )}
      
      {expense && <TimesheetExpenseCp expense={expenseData} payperiod={payperiod?.nextPayPeriod || ''} onSuccess={handleExpenseSuccess} />}
      
      <br/>
      <br/>
      <br/>
    </>
  );
}

export default TechWkTimesheet;
