// ServiceCallDetailsModal.js

import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import DateFormat from './DateFormat';

const ServiceCallDetailsModal = ({
  isOpen,
  onClose,
  serviceCall,
  onEdit,
}) => {
 
 
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-screen items-center justify-center p-4">
              <Dialog.Panel className="mx-auto max-w-xl w-full bg-white rounded-lg p-6 shadow-lg">
                <Dialog.Title className="text-lg font-medium text-gray-900">
                  Service Call Details
                </Dialog.Title>
                {serviceCall && (
                  <div className="mt-4 space-y-4">
                    <div>
                      <p className="text-sm font-medium text-gray-700">Customer:</p>
                      <p className="text-gray-900">{serviceCall.CUSTOMER}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Address:</p>
                      <p className="text-gray-900">{serviceCall.ADDRESS}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Trouble:</p>
                      <p className="text-gray-900">{serviceCall.TROUBLE}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Called By:</p>
                      <p className="text-gray-900">{serviceCall.CALLED_BY}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Phone:</p>
                      <p className="text-gray-900">{serviceCall.PHONE}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Technician:</p>
                      <p className="text-gray-900">{serviceCall.TECH}</p>
                    </div>

                      {Array.isArray(serviceCall.add_tech) && serviceCall.add_tech.length > 0 && (
                      <div>
                        <p className="text-sm font-medium text-gray-700">Additional Technician:</p>
                          {serviceCall.add_tech.map((tech) =>  (
                          <p key={tech.id} className="text-gray-900">
                            {tech.tech_name}
                          </p>
                          ))}
                      </div>
                      )}

                    <div>
                      <p className="text-sm font-medium text-gray-700">Time:</p>
                      <p className="text-gray-900">{serviceCall.TIME}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">End Time:</p>
                      <p className="text-gray-900">{serviceCall.end_time}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Schedule Date:</p>
                      <p className="text-gray-900"><DateFormat date={serviceCall.SCHEDULE_DATE}/></p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Notes:</p>
                      <p className="text-gray-900">{serviceCall.NOTES}</p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-700">Status:</p>
                      <p className="text-gray-900">{serviceCall.STATUS}</p>
                    </div>
                    {/* Add other fields as needed */}
                  </div>
                )}
                <div className="mt-6 flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={onClose}
                    className="inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-400"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    onClick={onEdit}
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700"
                  >
                    Edit
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default ServiceCallDetailsModal;
