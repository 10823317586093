import React, { useState, useEffect, useCallback } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/20/solid'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';
import Modal from '../components/Modal';
import ShortDateFormat from '../components/ShortDateFormat';
import PhoneNumber from '../components/PhoneNumber';
import LoadingSpinner from '../components/LoadingSpinner';
import Accordion from '../components/Accordion';
import { TrashIcon } from '@heroicons/react/24/outline';
import SelectParts from '../components/SelectParts';


const TechServiceDetail = () => {
  const navigate = useNavigate();
  const [serviceCalls, setServiceCalls] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [customerItem, setCustomerItem] = useState({});
  
  const [noChargeSet, setNoChargeSet] = useState(false);
  const [isNoCharge, setIsNoCharge] = useState(false);

  const [additionalTechs, setAdditionalTechs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedTech, setSelectedTech] = useState(null);
  const [message, setMessage] = useState('');
    
  const [techNotes, setTechNotes] = useState('');
  const [serviceComplete, setServiceComplete] = useState({ completeTicket: false, notCompletedTicket: false });
  const [openIndex, setOpenIndex] = useState(null);

  // hours
  const [hourList, setHourList] = useState([]);
  const [service, setService] = useState({total_hours:'', hour_type: '' });

  // adding tech
  const [additionTech, setAdditionTech] = useState([]);
  const [addTech, setAddTech] = useState({additional_tech:''});

  // adding parts
  const [servicePart, setServicePart] = useState({ part: '', quantity: 1 });
  const [parts, setParts] = useState([]);
  const [selectParts, setSelectedParts] = useState(null);  
  const [skipPart, setSkipPart] = useState(false);
  const [partsList, setPartsList] = useState([]);

  const [keyController, setKeyController] = useState(true);

   // Customer email/signature states
  const [serviceEmail, setServiceEmail] = useState({
    customerEmail: '',
    customerPrint: '',
    noEmail: false
  });
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [customerEmailMessage, setCustomerEmailMessage] = useState('');
  const [activateSignature, setActivateSignature] = useState(false);
  const [submitEmailButton, setSubmitEmailButton] = useState(false);
  // const []
  
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    setLoading(true);
    fetchServiceCalls(); //service call
    fetchEmployees(); //tech list
   }, [apiUrl, navigate, id]);

    // charge or no charge
  useEffect(() => {
    if (serviceCalls?.TYPE) {
      setIsNoCharge(serviceCalls.TYPE === 'NO CHARGE');
    }
  }, [serviceCalls]);
 
  const fetchServiceCalls = async () => { 
    try {
      const response = await axios.get(`${apiUrl}/service_crud.php?getDetailTech=${id}`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      if (response.data[0] === 'success') {
        const serviceCallData = response.data[1] ?? [];
        
        // Set service call data
        setServiceCalls(serviceCallData);

        //hours
        const listHours = Array.isArray(response.data[1].add_hours) ? response.data[1].add_hours : [];
        setHourList(listHours || [])
        setActivateSignature(listHours || true)

        //addition tech
        const additionTech = Array.isArray(response.data[1].additional_tech) ? response.data[1].additional_tech : [];
        setAdditionTech(additionTech || [])

        //adding parts
        const partDevice = Array.isArray(response.data[1].add_parts) ? response.data[1].add_parts : [];
        setPartsList(partDevice || [])
        
        //handle techNotes
        const addTechNotes = serviceCallData.TECHNOTES || ''; // Ensure TECHNOTES is set to an empty string if it's not available
        setTechNotes(addTechNotes);

        //handle customer email / customer print name
        const customerEmail = serviceCallData.CUSTOMEREMAIL || ''; 
        const customerPrint = serviceCallData.CUSTOMER_PRINT || '';
        if(serviceCallData.CUSTOMEREMAIL === 'noemail')
        {
          setServiceEmail({   customerEmail: customerEmail, customerPrint: customerPrint, noEmail:true});          
        }
        else
        {
          setServiceEmail({   customerEmail: customerEmail, customerPrint: customerPrint, noEmail:false});          
        }

        if(customerPrint && customerEmail)
        {
          setSubmitEmailButton(true);
        }

        // Handle no charge logic
        setIsNoCharge(serviceCallData?.TYPE === 'NO CHARGE');
        
      } else {
        toast.error(response.data.error, { position: "top-center" });
      }
      
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(`${apiUrl}/service_crud.php?employee_list=true`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      const serviceCalls = Array.isArray(response.data) ? response.data : [];
      if (serviceCalls.length === 0) {
        toast.error("No service calls found!", { position: "top-center" });
      }
      setEmployees(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
      }
    } finally {
      setLoading(false);
    }
  };  
  
  const handleCheckboxClick = async (id, currentType) => { //done
    const newType = currentType === 'NO CHARGE' ? 'CHARGE' : 'NO CHARGE';
    setLoading(true);

    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, {
        status: 'handleCharge',
        id: id,
        type: newType
      }, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data[0] === 'success') {
        // After successful API response, update the TYPE and checkbox state
        setServiceCalls((prevCall) => ({
          ...prevCall,
          TYPE: newType
        }));

        // Sync checkbox with updated type
        setIsNoCharge(newType === 'NO CHARGE');
        
        toast.success(`Service call updated to ${newType}!`, { position: 'top-center' });
      } else {
        toast.info('Failed to change type.', { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Session expired. Please login again.', { position: 'top-center' });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }
  };

// ************** adding hours below ******************
  const handleHoursChange = (e) => {
    setService({ ...service, total_hours: e.target.value });
  };

  const handleSubmitHours = async () => {
    if(service.hour_type === '' || service.total_hours === '')
    {
      toast.info('Missing hour type.', { position: 'top-center' });
      setLoading(false);
      return; // Prevent adding duplicate tech 
    }

    const bodyData = {
      status: "addHours",
      hour_type: service.hour_type,
      total_hours: service.total_hours,
      service_call_id: id
    };

      try {
        const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });
        
        if (response.data[0] === 'success') {
          // const addHours = {total_hours: service.total_hours};
          setHourList([...hourList, { total_hours: service.total_hours, hour_type: service.hour_type }]);
          setActivateSignature(true)

            // setServiceCalls((prevServiceCalls) => ({
            //   ...prevServiceCalls, // Keep the previous state
            //   additional_tech: [...(prevServiceCalls.add_hours || []), hourList], // Append newTech to the existing additional_tech array
            // }));

          toast.success('Tech added!', {
            position: 'top-center',
          });
          toggleAccordion(0);
        } else {
          toast.info("Failed to add tech", { position: "top-center" });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
            position: "top-center"
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
            position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }

      setOpenIndex(null);
      setService({total_hours:'', hour_type:''})
    // }
  };

  const handleHourTypeChange = (e) => {
    setService({ ...service, hour_type: e.target.value });
  };
  
  const handleDeleteHours = async(hrsID) => {
    setLoading(true);
    const bodyAddData = {
      status: "removeAddHours",
      hours_id: hrsID,
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyAddData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data[0] === 'success') {
          setHourList(hourList.filter((hrs) => hrs.id !== hrsID));
          toast.success('Hrs removed!', { position: 'top-center' });
          setActivateSignature(false)
          // toggleAccordion();
      } else {
        toast.info("Failed to remove Hrs", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }
  }
// ************** adding hours above *****************

// ************** adding tech notes below *****************
  const handleTechNotesChange = (e) => {
    setTechNotes(e.target.value); // Correctly update the state with the input value
  };

  const handleSubmitTechNotes = async () => {
    setLoading(true);

    const bodyData = {
      status: "addTechNotes", // Assuming this is the correct status for your backend
      techNotes: techNotes,  // Send the updated tech notes
      id: id,  // Assuming id is the service call ID
    };

    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data[0] === 'success') {
        setServiceCalls((prevState) => ({
          ...prevState,
          TECHNOTES: techNotes, // Update the state with the new tech notes
        }));
        toast.success('Tech notes updated!', { position: 'top-center' });
        toggleAccordion(3); // Close the accordion after submission if needed
      } else {
        toast.info('Failed to update tech notes', { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops! You don't have access to this area. Speak to the CFO.", { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }
  };
  // ************** adding tech notes above *****************

  //**************** the code below is add part ****************
  const handlePartSelect = newPart => {
    if (newPart && newPart.part) {
      setSelectedParts(newPart);
      setParts(prev => ({
        ...prev,
        part: newPart.part,
        id: newPart.id
      }));
    } else {
      //console.error('Invalid company selected:', newCompany);
    }
  };

  const handleAddParts = async() => {
    setLoading(true);
    const isAlreadyAdded = partsList.some((part) => part.part === selectParts.part); // Convert both to numbers
    
    if (isAlreadyAdded) {
      toast.info(selectParts.part+' has already been added.', { position: 'top-center' });
      setSelectedParts('');
      setLoading(false);
      return; // Prevent adding duplicate tech
    }
    
    const bodyData = {
      status: "addParts",
      id: id,
      parts_quantity: parseInt(servicePart.quantity),
      part: selectParts.part
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
        const newPartAdded = Array.isArray(response.data[1]) ? response.data[1] : [response.data[1]];
        setPartsList((prevPart) => [...prevPart, ...newPartAdded]);
        toast.success('Part added!', {
          position: 'top-center',
        });
        setSelectedParts('');
        toggleAccordion(2); // Close the accordion or perform other UI actions
      } else {
        toast.info("Failed to add part", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }    
  }

  const handlePartsChange = (e) => {
     setServicePart({ ...servicePart, quantity: e.target.value });
  };

  const handleRemovePart = async(partId) => {
        setLoading(true);
    const bodyAddData = {
      status: "removePart",
      removePartId: partId,
    };

    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyAddData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
          setPartsList(partsList.filter((part) => part.id !== response.data[1]));
          toast.success('Part removed!', { position: 'top-center' });
          // toggleAccordion();
      } else {
        toast.info("Failed to remove part", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }
  }
//**************** the code below is add part ****************

//**************** the code below is additional tech ****************
  const handleTechChange = async (employeeId, employeeName, serviceCallId) => { 
    setLoading(true);
    const isAlreadyAdded = additionTech.some((tech) => parseInt(tech.tech_id) === parseInt(employeeId)); // Convert both to numbers
    
    if (isAlreadyAdded) {
      toast.info('This technician has already been added.', { position: 'top-center' });
      setLoading(false);
      return; // Prevent adding duplicate tech
    }
    
    if ( employeeName === serviceCalls.TECH) {
      toast.info('You cant add yourself.', { position: 'top-center' });
      setLoading(false);
      return; // Prevent adding duplicate tech
    }

    const bodyData = {
      status: "additionalTech",
      tech_id: employeeId,
      id: serviceCallId,
      tech_name: employeeName
    };

    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
        const newTech = Array.isArray(response.data[1]) ? response.data[1] : [response.data[1]];
        setAdditionTech((prevTech) => [...prevTech, ...newTech]);
        toast.success('Tech added!', {
          position: 'top-center',
        });
        toggleAccordion(0); // Close the accordion or perform other UI actions
      } else {
        toast.info("Failed to add tech", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveTech = async (addition_tech_id) => { 
    setLoading(true);
    const bodyAddData = {
      status: "removeAdditionalTech",
      addition_tech_id: addition_tech_id,
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyAddData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
          setAdditionTech(additionTech.filter((tech) => tech.id !== response.data[1]));
         
          toast.success('Tech removed!', { position: 'top-center' });
          // toggleAccordion();
      } else {
        toast.info("Failed to remove tech", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }
  };
//**************** the code above is additional tech ****************


  const handleCompleteServiceCall = async(type) => {
    setLoading(true);
    const bodyData = {
      status: type, 
      id: id, 
    };

    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.data[0] === 'success') {
        setServiceComplete((prevState) => ({
          ...prevState,
          [type]: !prevState[type]
        }));
        toast.success('done!', { position: 'top-center' });
        toggleAccordion(4); // Close the accordion after submission if needed
        navigate(`/t/tservlist`)
      } else {
        toast.info('Failed to update', { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops! You don't have access to this area. Speak to the CFO.", { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }

  };  

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setServiceEmail((prevState) => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'customerEmail') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setIsValidEmail(emailPattern.test(value));
    }
  };

  const handleCheckboxChange = async() => {
    setServiceEmail((prevState) => ({
      ...prevState,
      noEmail: !prevState.noEmail
    }));
    if(serviceEmail.noEmail){
      return 
    }
    else 
    {
    const bodyData = {
      status: "updateNoEmail",
      id: id,
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
        setServiceEmail((prevState) => ({ ...prevState, noEmail: !prevState.noEmail, customerEmail: 'noemail', customerPrint: 'noemail',
        }));
        toast.success('noemail added!', {
          position: 'top-center',
        });
        toggleAccordion(null); // Close the accordion or perform other UI actions
      } else {
        toast.info("Failed to update no-email", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }      
    }

    
  };

  const handleSubmitCustomerEmail = async() => {
    if (serviceEmail.customerEmail && serviceEmail.customerPrint) {
    setLoading(true);
    const bodyData = {
      status: "addServiceEmail", // Assuming this is the correct status for your backend
      customer_email: serviceEmail.customerEmail,
      customer_print: serviceEmail.customerPrint,
      id: id,  // Assuming id is the service call ID
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
        setServiceCalls((prevState) => ({
          ...prevState,
          CUSTOMEREMAIL: serviceEmail.customerEmail, CUSTOMER_PRINT: serviceEmail.customerPrint
        }));
        toast.success('Email updated!', { position: 'top-center' });
         navigate(`/t/tservsignature/${id}`)
        toggleAccordion(4); // Close the accordion after submission if needed
      } else {
        toast.info('Failed to update email', { position: 'top-center' });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops! You don't have access to this area. Speak to the CFO.", { position: 'top-center' });
      }
    } finally {
      setLoading(false);
    }
      // Send to backend if needed
    } else {
        toast.success('We need both email and customer\'s printed name!', { position: 'top-center' });
    }
  };

  const handleActivateSignature = () => {
    setActivateSignature(true);
    // setCustomerEmailMessage('Signature activated.');
    navigate(`/t/tservsignature/${id}`)
  };


  return (
    <div className='pb-10'>
    <HeadingCp label={"Service Call Detail"}/>

    <ul role="list" className="divide-y divide-gray-100 border p-3">
      {serviceCalls && (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="flex items-center text-sm gap-1">
            <p>{serviceCalls.SCHEDULE_DATE ? serviceCalls.SCHEDULE_DATE : <ShortDateFormat date={serviceCalls.DATE} />}</p>
            <p>{serviceCalls.TIME ? "@ " + serviceCalls.TIME : ''}</p>
          </div>
          <div key={serviceCalls.id} className="relative flex items-center">
            <div className="min-w-0 flex-1">
              <span aria-hidden="true" className="absolute inset-0" />
              <p className="text-sm font-medium text-green-700">{serviceCalls.CUSTOMER} | Log#{serviceCalls.id}</p>
              <p className="truncate text-sm text-gray-500">{serviceCalls.ADDRESS}</p>
              <p className="truncate text-xs text-gray-500">Called by: {serviceCalls.CALLED_BY} Tel: {serviceCalls.PHONE ? <PhoneNumber number={serviceCalls.PHONE}/> : ''}</p>
              <p className="truncate text-xs text-red-700">Trouble: {serviceCalls.TROUBLE}</p>
            </div>
          </div>
        </div>
      )}
    </ul>
    
    {/* done - no charge */}
    {/* <div className="flex items-center space-x-2 mt-4 ml-3 text-lg border p-4">
      <input
        id="no-charge"
        type="checkbox"
        checked={isNoCharge}
        onChange={() => handleCheckboxClick(serviceCalls?.id, serviceCalls.TYPE)}
        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded scale-150 mr-2"
      />
      <label htmlFor="no-charge" className="text-lg font-large text-gray-700">
        No Charge
      </label>
    </div> */}

      {/* adding tech */}
      <Accordion
        title="Additional Tech"
        content={
        <>
        <ul className="list-disc pl-4 mb-3">
          {additionTech.map((tech) => (
            <li key={tech.id} className="bg-gray-100 py-2 mb-2 rounded flex items-center">
              <span className="mr-2 hover:cursor-pointer" onClick={() => handleRemoveTech(tech.id)}>
                <TrashIcon className="h-5 text-red-600" />
              </span>
              <span>{tech.tech_name}</span>
            </li>
          ))}
        </ul>

        <select className="border border-gray-300 rounded-md px-6 py-2 w-full"
            value={addTech.additional_tech || ''}
            onChange={(e) => {
              const selectedOption = e.target.options[e.target.selectedIndex];
              const employeeId = e.target.value;
              const employeeName = selectedOption.textContent;

              handleTechChange(employeeId, employeeName, serviceCalls.id); 
            }}
        >
          <option value="">Select Employee</option>
          {employees && employees.length > 0 ? (
            employees.map((employee) => (
              <option key={employee.id} className="sm:text-lg" value={employee.id}>
                {employee.first_name} {employee.last_name}
              </option>
            ))
          ) : (
            <option value="" disabled>
              No employees found
            </option>
          )}
        </select>
        </>
        }
        isOpen={openIndex === 0}
        toggle={() => toggleAccordion(0)}
      />

      {/* add hours */}
      <div className='mb-3'>
      <Accordion
        title="Add Hours (required)"
        content={
          <>
            <ul className="list-disc pl-4 mb-3">
              {hourList.map((hrs, index) => (
                <li key={index} className="bg-gray-100 py-2 mb-2 rounded flex items-center">
                  <span className="mr-2 hover:cursor-pointer">
                    <TrashIcon className="h-5 text-red-600"  onClick={() => handleDeleteHours(hrs.id)}/>
                  </span>
                  <span>
                    {hrs.total_hours} hrs - {hrs.hour_type}
                  </span>
                </li>
              ))}
            </ul>

            <div className="flex items-center space-x-2 mb-3">
              <input
                type="number"
                placeholder="Hrs"
                value={service.total_hours || ''}
                onChange={handleHoursChange}
                className="w-1/3 border border-gray-300 rounded-md px-4 py-2"
              />

              <select value={service.hour_type || ''} onChange={handleHourTypeChange}
                className="w-1/3 border border-gray-300 rounded-md px-4 py-2" required>
                <option value="">Select Type</option>
                <option value="Overtime">Overtime</option>
                <option value="Regular">Regular</option>
                <option value="Holiday">Holiday/Sunday</option>
              </select>
              <button onClick={handleSubmitHours} className="w-1/3 px-4 py-2 bg-blue-500 text-white rounded">
                Add Hours
              </button>
            </div>
          </>
        }
        isOpen={openIndex === 1}
        toggle={() => toggleAccordion(1)}
      />
      </div>

      {/* add Parts */}
      <Accordion
        title="Add Parts (required)"
        content={
              <>
                <ul className="list-disc pl-4 mb-3">
                  {partsList.map((x) => (
                    <li key={x.id} className="bg-gray-100 py-2 mb-2 rounded flex items-center">
                      <span className="mr-2 hover:cursor-pointer" onClick={() => handleRemovePart(x.id)}>
                        <TrashIcon className="h-5 text-red-600" />
                      </span>
                      <span>{x.part} x {x.quantity}</span>
                    </li>
                  ))}
                </ul>


                {/* Part description and quantity side by side */}
                <div className="flex space-x-2 mb-3">
                  <div className="flex-1 rounded-md py-2 w-[70%]">
                    <SelectParts
                      label="find part"
                      items={parts}
                      selectedItem={selectParts}
                      setSelectedItem={handlePartSelect}
                      apiUrl={`${apiUrl}/service_crud.php`}
                    />
                  </div>
                  <input
                    type="number"
                    name="quantity"
                    placeholder="Quantity"
                    value={servicePart.quantity}
                    onChange={handlePartsChange}
                    required
                    className="border border-gray-300 rounded-md px-2 py-2 w-1/4 mt-2 h-full"
                  />
                </div>

                {/* Buttons below inputs */}
                <div className="flex space-x-4 mt-4">
                  <button className="px-4 py-2 bg-blue-500 text-white rounded text-md" onClick={handleAddParts}>
                    Add Parts
                  </button>

                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded text-md"
                    onClick={() => {
                      setSkipPart(true);
                      toggleAccordion(2);
                    }}
                  >
                    Skip Parts
                  </button>
                </div>
              </>
        }
        isOpen={openIndex === 2}
        toggle={() => toggleAccordion(2)}
      />

      {/* Tech Notes */}
      <Accordion
        title="Tech's Notes (required)"
        content={
          <>
            <textarea
              value={techNotes || ''}
              onChange={handleTechNotesChange}
              className="w-full border border-gray-300 rounded-md px-4 py-2 mb-3"
              rows="4"
            />
            <button onClick={handleSubmitTechNotes} className="px-4 py-2 bg-blue-500 text-white rounded">
              Submit Notes
            </button>
          </>
        }
        isOpen={openIndex === 3}
        toggle={() => toggleAccordion(3)}
      />

      {/* Customers email signature */}
      <Accordion
        title="Customer's Email/Signature"
        content={
          <>
            {!serviceEmail.noEmail && (
              <>
                 {!isValidEmail && (
                  <p className="text-red-500 text-sm">Valid Email Format</p>
                )}
                <input
                  type="email"
                  name="customerEmail"
                  value={serviceEmail.customerEmail || ''}
                  onChange={handleEmailChange}
                  className={`w-full border border-gray-300 rounded-md px-4 py-2 mb-3 ${!isValidEmail && 'border-red-500'}`}
                  placeholder="email@email.com"
                />
 
                <input
                  type="text"
                  name="customerPrint"
                  value={serviceEmail.customerPrint || ''}
                  onChange={handleEmailChange}
                  className="w-full border border-gray-300 rounded-md px-4 py-2 mb-3"
                  placeholder="Print Name"
                />
                <div className="flex space-x-2 mb-3">
                    <>
                      <button
                        className={`px-4 py-2 text-white rounded 
                        ${serviceEmail.customerEmail && serviceEmail.customerPrint  ? 'bg-blue-500 hover:bg-blue-700' :'bg-blue-300 cursor-not-allowed'
                        }`}
                        onClick={handleSubmitCustomerEmail}
                        disabled={!serviceEmail.customerEmail || !serviceEmail.customerPrint || !activateSignature}
                      >
                        Submit Email
                      </button>
                      
                      {submitEmailButton && (
                        <button
                          className={`px-4 py-2 text-white rounded ${
                            activateSignature ? 'bg-blue-500 hover:bg-blue-700' :'bg-blue-300 cursor-not-allowed'
                          }`}
                          onClick={handleActivateSignature}
                          disabled={!activateSignature}>
                         view Signature
                        </button>
                      )}
                    </>
                  {!activateSignature && (  <div className='text-red-500'>Hours missing!!</div>  )}
                </div>
              </>
            )}

            <div className="flex items-center space-x-2 mt-5">
              <input
                type="checkbox"
                checked={serviceEmail.noEmail}
                onChange={handleCheckboxChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded scale-150 text-lg"
              />
              <label className="text-sm text-gray-700">No Customer Available for Signature</label>
            </div>

            {(serviceEmail.noEmail || serviceCalls.CUSTOMEREMAIL === 'noemail') && (
              <>
              <div className="mt-8 mb-5" >
                <input
                  type="radio"
                  name="completeTicket"
                  checked={serviceComplete.completeTicket}
                  onChange={() => handleCompleteServiceCall('completeTicket')}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded scale-150 text-lg"
                  disabled={!keyController}/>
                <label className="ml-2 text-sm">Fully completed the service call</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="completeTicket"
                  checked={serviceComplete.notCompletedTicket}
                  onChange={() => handleCompleteServiceCall('notCompletedTicket')}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded scale-150 text-lg"
                  disabled={!keyController}
                />
                <label className="ml-2 text-sm text-red-500">(Not fully completed the service call)</label>

                {customerEmailMessage && (
                  <div className="text-green-500 text-sm mt-2">{customerEmailMessage}</div>
                )}
              </div>
              </>
            )}

          </>
        }
        isOpen={openIndex === 4}
        toggle={() => toggleAccordion(4)}
      />

    </div>
  );
};

export default TechServiceDetail;
