import React, { useState, useEffect, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RadioGroup } from '@headlessui/react'
import DateFormat from '../components/DateFormat';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { UserCircleIcon } from '@heroicons/react/24/outline';
import AvatarLocation from '../components/AvatarLocation';
import { toast } from 'react-toastify';


const EmployeeEditPage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [startDate, setStartDate] = useState(null);
  const [location, setLocation] = useState([]);
  const [department, setDepartment] = useState();
  const [noUser, setNoUser] = useState(false)
  const [username, setUsername] = useState(null);
  const navigate = useNavigate();

  const handleLocationChange = (event) => {
    setLocation(event.target.value || ''); 
    // setLocation(event.target.value || ''); 
  };

  const { id } = useParams();
  const [employee, setEmployee] = useState({
                                              id: '',
                                              user_id: '',
                                              first_name: '',
                                              last_name: '',
                                              date_hire: '',
                                              o_f_type: '',
                                              title: '',
                                              email: '',
                                              phone: '',
                                              department: '',
                                              pm: '',
                                              dob: '',
                                              avatar: '',
                                              });
  useEffect( () => {            
    const fetchEmployee = async () => {
      try {
        const res = await axios.get(`${apiUrl}/employee_crud.php`, {
          params: {
            getEmployee: id
          },
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
        const data = res.data;
        if(data.success)
        {
         setEmployee(data.employee || []);
         setUsername(data.user || null);
        }
        else
        {
          toast.error('this user does not exist!', {position:'top-center'})
          setNoUser(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
       }
      }
    };

    fetchEmployee();
  }, [id]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
     if(name === 'phone') {
      const phoneCleared = value.replace(/\D/g, '');
      if(phoneCleared.length > 10) {
       toast.info('Phone number can only be 10 digits', {position:'top-center'});
       return
      }
     }

     if(name === 'department'){
       const dept = value;
       if(dept === ''){
        toast.info('Department can not be empty', {position:'top-center'});
        return
       }
     }

      setEmployee(prev => ({
          ...prev,
          [name]: type === 'checkbox' ? checked : value
      }));
  };

  const handleSubmit = async (event) => {
      event.preventDefault();  // Prevent the default form submit behavior
 
      const bodyData = {
          status: 'saveEmployee',
          user_id: id,
          data: employee
      };
      
      try {
           const response = await axios.post(`${apiUrl}/employee_crud.php`, bodyData, {
              headers: { 'Content-Type': 'application/json' }
          });
          const data = response.data;
          if(data.success)
          {
            toast.info('saved!')
            navigate('/employee_list')
          }
          else
          {
            toast.error(data.error)
          }
      } catch (error) {
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
      }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className='w-[800px] px-2 bg-white border rounded-lg py-8 px-8'>
        <div className="space-y-12">
          
          {/* header */}
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {employee.id? (
                <>
                  Edit {employee.first_name} {employee.last_name}
                </>
                ) 
              : 
              (<>
                New Employee username: <span className='text-green-600'> {username}</span>
                </>
              )}               
            </h2>
              {employee.date_hire && (
                <p className="mt-1 text-sm leading-6 text-red-600">
                  Date Hire: {employee.date_hire ? <DateFormat date={employee.date_hire} /> : ""}
                </p>
              )}
          </div>

          <div className="border-b border-gray-900/10 pb-12 ">
            {employee.id && (
              <h6 className="text-base font-semibold leading-7 text-gray-600">
                ID: {employee.id}
              </h6>
            )}

            {/* date hire */}
            <div className="w-full sm:w-1/3">
              <label htmlFor="date_hire" className="block text-sm font-medium leading-6 text-gray-900">
                Date Hire <span className='text-red-500'>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="date_hire"
                  id="date_hire"
                  value={employee.date_hire || ''}
                  onChange={handleChange}
                  className="text-slate-500 required:border-red-500 block w-full 
                              rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                              ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                              focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>
            </div>

            {/* Radio Buttons for Location */}
            <div className="w-full sm:w-1/3 mt-8">
              <span className="block text-sm font-medium leading-6 text-gray-900">
                Position <span className='text-red-500'>*</span>
              </span>
              <div className="mt-2 flex items-center space-x-4">
                <label className="flex items-center space-x-2 cursor-pointer hover:text-blue-500">
                  <input
                    type="radio"
                    value="Office"
                    name="o_f_type"
                    checked={employee.o_f_type === 'Office'}
                    onChange={handleChange}
                    className="text-blue-600 focus:ring-blue-500 border-gray-300"
                    required
                  />
                  <span>Office</span>
                </label>
                <label className="flex items-center space-x-2 cursor-pointer hover:text-blue-500">
                  <input
                    type="radio"
                    value="Field"
                    name="o_f_type"
                    checked={employee.o_f_type === 'Field'}
                    onChange={handleChange}
                    className="text-blue-600 focus:ring-blue-500 border-gray-300"
                    required
                  />
                  <span>Field</span>
                </label>
              </div>
            </div>

              <div className="sm:col-span-3 mt-8">
                <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
                  Email <span className='text-red-500'>*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={employee.email || ''}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="text-slate-500 required:border-red-500 block w-full 
                                rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required/>
                </div>
              </div>            

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
                  First Name <span className='text-red-500'>*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    value={employee.first_name || ''}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="text-slate-500 required:border-red-500 block w-full 
                                rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required/>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                  Last Name <span className='text-red-500'>*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    value={employee.last_name || ''}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="text-slate-500 required:border-red-500 block w-full 
                                rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required/>
                </div>
              </div>

              <div className="sm:col-span-1">
                <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                  PM Initials
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="pm"
                    id="pm"
                    value={employee.pm || ''}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="text-slate-500 required:border-red-500 block w-full 
                                rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="company" className="block text-sm font-medium leading-6 text-gray-900">
                  Title <span className='text-red-500'>*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={employee.title || ''}
                    onChange={handleChange}
                    autoComplete="given-name"
                    className="text-slate-500 required:border-red-500 block w-full 
                                rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                                ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required/>
                </div>
              </div>

              {/* select department */}
              <div className='sm:col-span-3'>
                <label htmlFor="department" className="block text-sm font-medium leading-6 text-gray-900 ">
                  Department <span className='text-red-500'>*</span>
                </label>
                <select
                  id="department"
                  name="department"
                  onChange={handleChange}
                  value={employee.department || ''}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900
                            ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" >
                  <option value="">Select Department</option>
                  {employee.o_f_type === 'Office' && (
                  <>
                    <option value="Admin">Admin</option>
                    <option value="Office">Office</option>
                    <option value="Sales">Sales</option>
                    <option value="Finance">Finance</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Purchasing">Purchasing</option>
                    <option value="Operations">Operations</option>                    
                    <option value="Service">Service</option>                    
                    <option value="Maintenance">Maintenance</option>               
                    <option value="Install">(PMs)Install</option>               
                  </>
                  )}
                  {employee.o_f_type === 'Field' && (
                  <>
                    <option value="Install">Install</option>
                    <option value="Service">Service</option>
                    <option value="Maintenance">Maintenance</option>
                  </>
                  )}
                </select>
              </div>
            </div>
          </div>

          <div className="mt-10 pb-10">
            <div className="flex flex-wrap gap-x-6 gap-y-8">
            
            {employee.department === 'Sales' ? (
              <>
                {/* Sale's Code Field */}
                <div className="sm:col-span-1 sm:w-1/6">
                  <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                    Sales Code Initials
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="sale_code"
                      id="sale_code"
                      value={employee.sale_code || ''}
                      required
                      onChange={handleChange}
                      autoComplete="given-name"
                      className="text-slate-500 required:border-red-500 block w-full 
                                  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                                  ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                                  focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                </div>             
              </>
            ) : ('')}
          
            {/* Phone Field */}
            <div className="w-full sm:w-1/3">
              <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                Phone <span className='text-red-500'>*</span>
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  value={employee.phone || ''}
                  onChange={handleChange}
                  autoComplete="tel"
                  className="text-slate-500 required:border-red-500 block w-full 
                              rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                              ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                              focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                />
              </div>
            </div>

            {/* DOB Field */}
            <div className="w-full sm:w-1/3">
              <label htmlFor="dob" className="block text-sm font-medium leading-6 text-gray-900">
                DOB
              </label>
              <div className="mt-2">
                <input
                  type="date"
                  name="dob"
                  id="dob"
                  value={employee.dob || ''}
                  onChange={handleChange}
                  className="text-slate-500 required:border-red-500 block w-full 
                              rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset 
                              ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
                              focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            </div>
          </div>         
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <NavLink to="/employee_list">
          <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
          </button>
          </NavLink>
          {!noUser && (
            <button
              type="submit"
              disabled={employee.department === null ? true : false}

              className={`rounded-md bg-green-600 ${employee.department===null && ('opacity-50')} px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline
                          focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}>
              Save
            </button>
          )}
        </div>
      </form>
    </div>
  )

}
export default EmployeeEditPage