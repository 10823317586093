import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import logo from '../assets/images/logo.png';

axios.defaults.withCredentials = true;

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  useEffect(() => {
    const validateToken = async () => {
      try {
        const response = await axios.post(`${apiUrl}/users_login.php`, { 
          status: 'validate',
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.data.valid) {
          navigate('/');
        }
      } catch (error) {
        // console.error("Error during token validation:", error);
      }
    };

    validateToken();
  }, [navigate]);

  const authenticateUser = async () => {
    if (!username || !password) {
      toast("Username and password both required", {
        position: "top-center",
        autoClose: 5000
      });
      return;
    }

    const bodyData = {
      status: 'userauth',
      data: { username, password }
    };

    try {
      const response = await axios.post(`${apiUrl}/users_login.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.authenticated) {
        if(response.data.location === 'Field') {
          navigate('/t');
        } else {
          navigate('/');
        }
        // toast("Authentication Successful", {
        //   position: "top-center",
        //   autoClose: 5000
        // });
      } else {
        toast("Authentication Failed", {
          position: "top-center",
          autoClose: 5000
        });
      }
    } catch (error) {
      console.error("Authentication error:", error);
      toast("An error occurred while trying to authenticate.", {
        position: "top-center",
        autoClose: 5000
      });
    }
  };

  return (
    <>
      <div className="flex min-h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white p-10 shadow-lg rounded-md">
          <div>
            <img className="mx-auto h-20 w-auto" src={logo} alt="irl-logo" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={e => e.preventDefault()}>
            <input type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="username" className="sr-only">Username</label>
                <input id="username" name="username" type="text" autoComplete="username" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">Password</label>
                <input id="password" name="password" type="password" autoComplete="current-password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
            </div>

            <div>
              <button type="submit" onClick={authenticateUser} className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
      
    </>
  );
};

export default Login;
