import React, { useState } from 'react';
import SideNav from '../components/SideNav';
import DayOff from '../components/DayOff';
import Alerts from '../components/Alerts';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectSalesUser from '../components/SelectSalesUser';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SubmitButton from '../components/SubmitButton';

const VacationRequestPage = () => {
  const [newCert, setNewCert] = useState({
    employee_id: '',
    date_out: '',
    date_end: '',
    status: 'R',
  });

  const [isHalfDay, setIsHalfDay] = useState(false);

  const navigate = useNavigate();

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewCert((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    setIsHalfDay(e.target.checked);
    if (e.target.checked) {
      setNewCert((prevState) => ({
        ...prevState,
        date_end: '',
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const today = new Date();
    const dateOut = new Date(newCert.date_out);

    // Check if the date_out is in the past
    if (dateOut < today.setHours(0, 0, 0, 0)) {
      toast.error("The start date cannot be earlier than today's date.", {
        position: "top-center",
      });
      return;
    }

    // Check if date_out is a Saturday or Sunday
    const dayOfWeek = dateOut.getDay(); // 0 is Sunday, 6 is Saturday
    if (dayOfWeek === 6 || dayOfWeek === 5) {
      toast.error("The start date cannot be on a weekend (Saturday or Sunday).", {
        position: "top-center",
      });
      return;
    }  
    
    const apiUrl = process.env.REACT_APP_API_URL;
    const type  = (isHalfDay ? 'half_vday' : 'vday');

    const bodyData = {
      ...newCert,
      date: new Date().toISOString().split('T')[0], // current date in YYYY-MM-DD format
      status: 'vacation_request',
      type: type
    };

    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      const data = response.data;
      if (data.success) {
        toast.success("Vacation request submitted successfully!", {
          position: "top-center",
        });

        setNewCert({
          employee_id: '',
          date_out: '',
          date_end: '',
          status: 'R',
        });
        setIsHalfDay(false);
      }
      else if(data.error)
      {
        toast.info('A vacation request or approval already exists for one or more of the selected dates.', {
          position: "top-center",
        });
      }
      else 
      {
        toast.error("Failed verify request dates. ", {
          position: "top-center",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("You don't have access to this area. Speak to the CFO.", {
          position: "top-center",
        });
      }
    }
    
  };

  const isEndDateInvalid = newCert.date_out && newCert.date_end && new Date(newCert.date_end) <= new Date(newCert.date_out);

  const isDateOutInvalid = newCert.date_out && new Date(newCert.date_out) < new Date().setHours(0, 0, 0, 0);

  return (
    <>
      <div className="max-w-[1220px] mx-auto grid grid-cols-1 md:grid-cols-[200px_minmax(540px,_1fr)_260px] gap-x-4">
        {/* Side Nav: 200px, hidden on small screens */}
        <div className="hidden md:block">
          <div className='pt-6 pr-3'>
            <SideNav />
          </div>
          <br />
          <br />
        </div>

        {/* Main Content: Expand on small screens */}
        <div className="p-4">
          <div className='bg-white p-10 rounded-lg drop-shadow-md'>
            <h4 className='mt-10 text-lg'>Vacation Request</h4>
            <p className='text-red-600'>
              NOTE !!
              All employee(s) covered by this agreement will use
              all available vacation days before requesting a no pay day.
              No vacation will be granted for increments of less than <sup>1</sup>&frasl;<sub>2</sub> day.
            </p>

            <form onSubmit={handleSubmit}>
              <div className="mt-4 max-w-[250px]">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    checked={isHalfDay}
                    onChange={handleCheckboxChange}
                  />
                  <span className="ml-2">Half Vacation Day</span>
                </label>
              </div>

              <div className="mt-4 max-w-[250px]">
                <label className="block text-sm font-medium text-gray-700">Date out</label>
                <input
                  type="date"
                  name="date_out"
                  value={newCert.date_out}
                  required={true}
                  onChange={handleNewInputChange}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                {isDateOutInvalid && (
                  <p className="text-red-600 text-sm mt-1">The start date cannot be earlier than today's date.</p>
                )}
              </div>
              <br />

              {!isHalfDay && (
                <div className="mt-4 max-w-[250px]">
                  <label className="block text-sm font-medium text-gray-700">Date end (date returning to work)</label>
                  <input
                    type="date"
                    name="date_end"
                    value={newCert.date_end}
                    onChange={handleNewInputChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  {isEndDateInvalid && (
                    <p className="text-red-600 text-sm mt-1">Date end cannot be the same or before Date out.</p>
                  )}
                </div>
              )}

              <div className="mt-6">
                <button
                  disabled={!newCert.date_out || (!isHalfDay && (isEndDateInvalid || !newCert.date_end)) || isDateOutInvalid}
                  className={`inline-flex justify-center rounded-md border border-transparent
                            ${!newCert.date_out || (!isHalfDay && (isEndDateInvalid || !newCert.date_end)) || isDateOutInvalid ? ' bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'}
                            py-2 px-4 text-sm font-medium text-white shadow-sm 
                            focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}>
                  Request
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Widgets: 200px, hidden on small screens */}
        <div className="hidden md:block">
          <div className='pt-4'>
            <div className='border-2'>
              <DayOff />
            </div>
            <br />
            <Alerts />
          </div>
        </div>
      </div>
    </>
  );
};

export default VacationRequestPage;
