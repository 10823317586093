import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';
import DateFormat from '../components/DateFormat';
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, NavLink } from 'react-router-dom';
import SideNav from '../components/SideNav';
import HeadingCp from '../components/HeadingCp';
import AvatarLocation from '../components/AvatarLocation';
import DayOff from '../components/DayOff';
import Alerts from '../components/Alerts';

const CheckInOutPage = () => { 
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/tech_crud.php`;
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [checkInOutData, setCheckInOutData] = useState([]);
  const navigate = useNavigate();
  const [dateTo, setDateTo] = useState('');
  const [employees, setEmployees] = useState([]);
  const [filterEmployee, setFilterEmployee] = useState('');

  // Fetch initial data
  const fetchCheckInOutData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(api, {
        params: { vcheckinout: true },
        headers: { 'Content-Type': 'application/json' },
      });
      const data = Array.isArray(response.data) ? response.data : [];
      if (data.length === 0) {
        toast.error("No records found!", { position: "top-center" });
      }
      setCheckInOutData(data);
    } catch (error) {
      // Error handling...
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCheckInOutData();
  }, [api, navigate]);

  // Fetch employees
  useEffect(() => {
    setLoading(true);
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?employee_list=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        const employeeList = Array.isArray(response.data) ? response.data : [];
        if (employeeList.length === 0) {
          toast.error("No employees found!", { position: "top-center" });
        }
        setEmployees(employeeList);
      } catch (error) {
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
      } finally {
        setLoading(false);
      }
    };
    fetchEmployees();
  }, [apiUrl, navigate]); 

  // Handle filter
  const handleFilter = async () => {
    if (!filterEmployee || !dateTo) {
      toast.error('Please select an employee and date.', { position: 'top-center' });
      return;
    }
    setLoading(true);
    const bodyData = {
      status: 'getFilter',
      date_to: dateTo,
      employee_id: filterEmployee,
    };
    
    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      const filteredData = Array.isArray(response.data) ? response.data : [];
      if (filteredData.length === 0) {
        toast.info("No records found for the selected criteria.", { position: "top-center" });
      }
      setCheckInOutData(filteredData);
    } catch (error) {
     if (error.response && error.response.status === 401) {
     toast.error("Session expired. Please login again.", {
     position: "top-center"
     });
     navigate('/login');
     } else if (error.response && error.response.status === 403) {
     toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
     position: "top-center"
     });
     }
    } finally {
      setLoading(false);
    }
  };  

  // Handle search input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter data based on search term
  const filteredData = checkInOutData.filter((item) =>
    item.job_location.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.check_status.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.tech_id.toString().includes(searchTerm)
  );

  return (
    <div className="max-w-[1220px] mx-auto grid grid-cols-1 md:grid-cols-[200px_minmax(540px,_1fr)_260px] gap-x-4">
      {/* Side Navigation */}
      <div className="hidden md:block">
        <div className='pt-6 pr-3'>
          <SideNav />
        </div>
      </div>

      {/* Main Content */}
      <div className="p-4">
        <div className='px-4'>
          {/* Heading */}
          <div className="sm:flex sm:items-center">
            <HeadingCp label={'Check In/Out Records'} />
          </div>

          {/* Search Bar */}
          <div className="sm:flex sm:items-center py-1">
            <div className="flex w-full items-center mt-5 sm:mt-0">
              <div className="flex-grow">
                <div className="flex space-x-3 items-center mb-8">
                  <div className="relative rounded-md shadow-sm flex-grow">
                    <input
                      type="text"
                      name="search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                      placeholder="Filter by Tech ID, Status, or Job Location"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Filter Bar */}
          <div className="flex items-center gap-4 max-w-6xl w-full mx-auto mb-6">
            <input
              type="date"
              className="border border-gray-300 rounded-md px-2 py-2"
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}/>

            <select
              className="border border-gray-300 rounded-md px-6 py-2"
              value={filterEmployee}
              onChange={(e) => setFilterEmployee(e.target.value)}>
              <option value="">Select Employee</option>
              {employees && employees.length > 0 ? (
                employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.first_name} {employee.last_name}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No employees found
                </option>
              )}
            </select>

            <button
              type="button"
              onClick={handleFilter}
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none 
                focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Filter
            </button>

            {/* Reset Button */}
            <button
              type="button"
              onClick={fetchCheckInOutData}
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                shadow-sm text-white bg-gray-500 hover:bg-gray-700 focus:outline-none 
                focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Reset
            </button>
          </div>

          {/* Data Cards */}
          {loading ? (
            <LoadingSpinner>Loading...</LoadingSpinner>
          ) : (
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {filteredData.length > 0 ? (
                filteredData.map((item) => (
                  <div
                    key={item.id}
                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm 
                    focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                  >
                    <div className="flex-shrink-0 ">
                      <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                        <AvatarLocation avatar={item.avatar} className={'h10'} />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1">
                      <div className="focus:outline-none">
                        <span aria-hidden="true" className="absolute inset-0" />
                        <p className="text-sm text-blue-500">
                          Status: {item.check_status}
                        </p>
                        <p className="text-sm font-medium text-gray-900">
                          {item.employee}
                        </p>
                        <p className="text-sm text-gray-500">
                          {item.job_location}
                        </p>
                        <p className="text-sm text-gray-500">
                          D: <DateFormat date={item.date} /> T: {new Date(item.time * 1000).toLocaleTimeString()}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-4 text-center text-gray-500">No records found.</div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Optional Right Sidebar */}
      <div className="hidden md:block ">
        <div className='pt-4'>
          <div className='border-2'>
            <DayOff />
          </div>
          <br />
          <Alerts />
        </div>
      </div>
    </div>
  );
};

export default CheckInOutPage;
