import React, { useState, useEffect } from 'react';
import { TrashIcon, PencilIcon, CheckIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateFormat from '../components/DateFormat';
import { useNavigate } from 'react-router-dom';
import HeadingCp from '../components/HeadingCp';
import ReimbursementCp from '../components/ReimbursementCp';
import LoadingSpinner from '../components/LoadingSpinner';
import Modal from '../components/Modal'; // Import your Modal component

const OfficeTimesheet = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [timesheets, setTimesheets] = useState([]);
  const [payperiod, setPayperiod] = useState({});
  const [editMode, setEditMode] = useState({});
  const navigate = useNavigate();
  const [newTimesheet, setNewTimesheet] = useState({
    event: '',
    time_in: '',
    time_out: '',
    overtime: '',
    date: '',
  });

  const [activeTab, setActiveTab] = useState('timesheet');
  const [reimbursements, setReimbursements] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newReimbursement, setNewReimbursement] = useState({
    amount: '',
    payroll_date: '',
    amount: '',
    file: '',
    description: ''
  });
 
  useEffect(() => {
    if (activeTab === 'timesheet') {
      fetchTimesheet();
    } else if (activeTab === 'reimbursement') {
      fetchReimbursements();
    }
  }, [activeTab]);

  const fetchTimesheet = async () => {
    try {
      const response = await axios.get(`${apiUrl}/time_attendance_crud.php`, {
        params: { office_timesheet: true },
      });
      if (response.data[0] === 'success') {
        setTimesheets(response.data.list || []);
        setPayperiod(response.data.pay_period);
      } else {
        toast.error('No data found', {
          position: 'top-center',
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: 'top-center',
        });
      }
    }
  };

  const handleDeleteTimesheet = async (id) => {
    try {
      const response = await axios.post(
        `${apiUrl}/time_attendance_crud.php`,
        {
          status: 'delete_office_timesheet',
          data: { id },
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data.success) {
        toast.success('Timesheet entry deleted successfully!', {
          position: 'top-center',
        });
        setTimesheets(timesheets.filter((timesheet) => timesheet.id !== id));
      } else {
        toast.error(response.data.message || 'Failed to delete timesheet entry.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.', {
        position: 'top-center',
      });
    }
  };

  const handleEditToggle = (id) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [id]: !prevEditMode[id],
    }));
  };

  const handleInputChange = (id, name, value) => {
    setTimesheets((prevTimesheets) =>
      prevTimesheets.map((timesheet) =>
        timesheet.id === id ? { ...timesheet, [name]: value } : timesheet
      )
    );
  };

  const handleSave = async (timesheet) => {
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`,
        { status: 'update_office_timesheet', data: timesheet,  },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data.success) {
        toast.success('Timesheet entry updated successfully!', {
          position: 'top-center',
        });
        
        setEditMode((prevEditMode) => ({
          ...prevEditMode,
          [timesheet.id]: false,
        }));
      } else {
        toast.error(response.data.message || 'Failed to update timesheet entry.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.', {
        position: 'top-center',
      });
    }
  };

  const formatTime12Hour = (time) => {
    let [hours, minutes] = time.split(':');
    const suffix = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format
    return `${hours}:${minutes} ${suffix}`;
  };

  const handleNewTimesheetChange = (e) => {
    const { name, value } = e.target;
    setNewTimesheet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddNewTimesheet = async () => {
    const week1Date = payperiod.week[0]; // Start of the pay period
    const week2Date = payperiod.week[6]; // End of the pay period
    const submittedDate = newTimesheet.date;

    // Check if the submitted date is within the valid range
    if (submittedDate < week1Date || submittedDate > week2Date) {
      toast.error(`Date must be between ${week1Date} and ${week2Date}.`, {
        position: 'top-center',
      });
      return; // Stop the submission
    }
    try {
      const response = await axios.post(
        `${apiUrl}/time_attendance_crud.php`,
        {
          status: 'office_timesheet_add',
          data: newTimesheet,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data[0] === 'success') {
        toast.success('Timesheet entry added successfully!', {
          position: 'top-center',
        });

        setTimesheets([...timesheets, { ...response.data[1], id: response.data[1].id }]);
        setNewTimesheet({
          event: '',
          time_in: '',
          time_out: '',
          overtime: '',
          date: '',
        });
      } else {
        toast.error(response.data.message || 'Failed to add timesheet entry.', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.', {
        position: 'top-center',
      });
    }
  };

  // Reimbursement functions
  const fetchReimbursements = async () => {
    try {
      const response = await axios.get(`${apiUrl}/time_attendance_crud.php?getReimbursement=true`, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data.success) {
        setReimbursements(response.data.reimbursements);
      } else {
        toast.error(response.data.error || 'Failed to fetch reimbursements');
      }
    } catch (error) {
      console.error('Error fetching reimbursements:', error);
      toast.error('An error occurred while fetching reimbursements');
    }
  };

  const handleEditReimbursement = async (editedReimbursement) => {
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
        status: 'updateReimbursement',
        data: editedReimbursement,
      });
      
      if (response.data.success) {
        setReimbursements((prevReimbursements) =>
          prevReimbursements.map((reimbursement) =>
            reimbursement.id === editedReimbursement.id ? editedReimbursement : reimbursement
          )
        );
        toast.success('Reimbursement updated successfully');
      } else {
        toast.error(response.data.error || 'Failed to update reimbursement');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    }
  };

  const handleAddReimbursement = async () => {
    const bodyData = {
      status: 'addNewReimbursement',
      data: newReimbursement
    }
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
        status: 'addNewReimbursement',
        data: newReimbursement,
      });
      if (response.data.success) {
        setReimbursements((prevReimbursements) => [...prevReimbursements, response.data.reimbursement]);
        toast.success('Reimbursement added successfully');
        setIsModalOpen(false);
        setNewReimbursement({
          amount: '',
          description: '',
        });
      } else {
        toast.error(response.data.error || 'Failed to add reimbursement');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    }
  };

  const handleDeleteReimbursement = async (id) => {
    try {
      const response = await axios.post(`${apiUrl}/time_attendance_crud.php`, {
        status: 'deleteReimbursement',
        id: id,
      });
      if (response.data.success) {
        setReimbursements((prevReimbursements) =>
          prevReimbursements.filter((reimbursement) => reimbursement.id !== id)
        );
        toast.success('Reimbursement deleted successfully');
      } else {
        toast.error(response.data.error || 'Failed to delete reimbursement');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    }
  };

  const handleFileUpload = async (file, reimbursementId) => {
    try {
      const formData = new FormData();
      formData.append('status', 'submit_reimbursement');
      formData.append('file', file);
      formData.append('id', reimbursementId);

      const response = await axios.post(`${apiUrl}/upload_reimbursement_file.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.data.success) {
        setReimbursements((prevReimbursements) =>
          prevReimbursements.map((reimbursement) =>
            reimbursement.id === reimbursementId
              ? { ...reimbursement, file: response.data.rem_file }
              : reimbursement
          )
        );
        toast.success('File uploaded successfully');
      } else {
        toast.error(response.data.error || 'Failed to upload file');
      }
    } catch (error) {
        if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
        position: "top-center"
        });
        navigate('/login');
        } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
        position: "top-center"
        });
        }
    }
  };

  const handleNewReimbursementChange = (e) => {
    const { name, value } = e.target;
    setNewReimbursement((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8 mt-12">
      <HeadingCp label={'Office Timesheet'} />

      {/* Tab Navigation */}
      <div className="border-b border-gray-200 mb-4">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          <button
            onClick={() => setActiveTab('timesheet')}
                className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                   activeTab === 'timesheet'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`} >
            Timesheet
          </button>
          <button
            onClick={() => setActiveTab('reimbursement')}
            className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
              activeTab === 'reimbursement'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            }`}
          >
            Reimbursement
          </button>
        </nav>
      </div>

      {activeTab === 'timesheet' && (
        <>
          <h6 className="py-8">
            Next Payroll Date: <DateFormat date={payperiod.payPeriod} />
          </h6>
          <div className="overflow-hidden">
          <table className="min-w-full text-left mb-20">
            <thead className="bg-gray-50 border-b border-gray-200">
              <tr>
                <th className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">Edit</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Event</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Time In</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Time Out</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Overtime</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                <th className="py-3.5 pl-3 text-left text-sm font-semibold text-gray-900">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {timesheets.map((timesheet, index) => (
                <tr
                  key={timesheet.id}
                  className={`divide-y ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                >
                  <td className="py-4 pr-3">
                    <TrashIcon
                      className="h-5 text-red-700 hover:cursor-pointer hover:text-red-500"
                      onClick={() => handleDeleteTimesheet(timesheet.id)}
                    />
                  </td>

                  <td className="px-3 py-4 text-sm text-gray-900">
                    {editMode[timesheet.id] ? (
                      <input
                        type="text"
                        name="event"
                        value={timesheet.event}
                        onChange={(e) => handleInputChange(timesheet.id, e.target.name, e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      timesheet.event
                    )}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-900">
                    {editMode[timesheet.id] ? (
                      <input
                        type="time"
                        name="time_in"
                        required
                        value={timesheet.time_in}
                        onChange={(e) => handleInputChange(timesheet.id, e.target.name, e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      formatTime12Hour(timesheet.time_in)
                    )}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-900">
                    {editMode[timesheet.id] ? (
                      <input
                        type="time"
                        name="time_out"
                        required
                        value={timesheet.time_out}
                        onChange={(e) => handleInputChange(timesheet.id, e.target.name, e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      formatTime12Hour(timesheet.time_out)
                    )}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-900">
                    {editMode[timesheet.id] ? (
                      <input
                        type="number"
                        name="overtime"
                        step="0.01"
                        required
                        value={timesheet.overtime}
                        onChange={(e) => handleInputChange(timesheet.id, e.target.name, e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      timesheet.overtime
                    )}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-900">
                    {editMode[timesheet.id] ? (
                      <input
                        type="date"
                        name="date"
                        value={timesheet.date}
                        onChange={(e) => handleInputChange(timesheet.id, e.target.name, e.target.value)}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : (
                      <DateFormat date={timesheet.date} />
                    )}
                  </td>
                  <td className="py-4 pl-3">
                    {editMode[timesheet.id] ? (
                      <CheckIcon
                        className="h-5 text-green-700 hover:cursor-pointer hover:text-green-500"
                        onClick={() => handleSave(timesheet)}
                      />
                    ) : (
                      <PencilIcon
                        className="h-5 text-blue-700 hover:cursor-pointer hover:text-blue-500"
                        onClick={() => handleEditToggle(timesheet.id)}
                      />
                    )}
                  </td>
                </tr>
              ))}
              {/* Row for adding a new timesheet entry */}
              <tr className="divide-y bg-gray-100">
                <td className="py-4 pr-3"></td>
                <td className="px-3 py-4 text-sm">
                  <input
                    type="text"
                    name="event"
                    required
                    value={newTimesheet.event}
                    onChange={handleNewTimesheetChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </td>
                <td className="px-3 py-4 text-sm">
                  <input
                    type="time"
                    name="time_in"
                    required
                    value={newTimesheet.time_in}
                    onChange={handleNewTimesheetChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </td>
                <td className="px-3 py-4 text-sm">
                  <input
                    type="time"
                    name="time_out"
                    required
                    value={newTimesheet.time_out}
                    onChange={handleNewTimesheetChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </td>
                <td className="px-3 py-4 text-sm">
                  <input
                    type="number"
                    name="overtime"
                    step="0.01"
                    required
                    value={newTimesheet.overtime}
                    onChange={handleNewTimesheetChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </td>
                <td className="px-3 py-4 text-sm">
                  <input
                    type="date"
                    name="date"
                    required
                    value={newTimesheet.date}
                    onChange={handleNewTimesheetChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </td>
                <td className="py-4 pl-3">
                  <CheckIcon
                    className="h-5 text-green-700 hover:cursor-pointer hover:text-green-500"
                    onClick={handleAddNewTimesheet}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </>
      )}

      {activeTab === 'reimbursement' && (
        <>
          {/* Header with Add Reimbursement button */}
          <div className="sm:flex sm:items-left">
            <div className="sm:flex-auto"></div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm
                          hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                          focus-visible:outline-indigo-600"
                onClick={() => setIsModalOpen(true)}
              >
                Add Reimbursement
              </button>
            </div>
          </div>

          {reimbursements.length === 0 ? ('No Reimbursement'
            // <LoadingSpinner />
          ) : (
            <ReimbursementCp
              reimbursements={reimbursements}
              searchTerm={searchTerm}
              onEditReimbursement={handleEditReimbursement}
              onAddReimbursement={handleAddReimbursement}
              access={'Admin'} // Adjust based on your user role
              onDeleteReimbursement={handleDeleteReimbursement}
              onFileUpload={handleFileUpload}
            />
          )}

          {/* Add Reimbursement Modal */}
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <h2 className="text-lg font-semibold text-gray-900">Add New Reimbursement</h2>
            <form>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <input
                  type="text"
                  name="description"
                  value={newReimbursement.description || ''}
                  required={true}
                  onChange={handleNewReimbursementChange}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">Amount</label>
                <input
                  type="number"
                  name="amount"
                  value={newReimbursement.amount}
                  onChange={handleNewReimbursementChange}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              {/* You can add a file input here if needed */}
              <div className="mt-6">
                <button
                  disabled={!newReimbursement.amount || !newReimbursement.description}
                  onClick={handleAddReimbursement}
                  type="button"
                  className={`inline-flex justify-center rounded-md border border-transparent
                              ${
                                !newReimbursement.amount || !newReimbursement.description
                                  ? 'bg-gray-400'
                                  : 'bg-indigo-600 hover:bg-indigo-700'
                              }
                              py-2 px-4 text-sm font-medium text-white shadow-sm 
                              focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                >
                  Add
                </button>
              </div>
            </form>
          </Modal>
        </>
      )}
    </div>
  );
};

export default OfficeTimesheet;
