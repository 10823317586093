import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import SubmitButton from '../components/SubmitButton';
import 'react-toastify/dist/ReactToastify.css';
import FormHeader from '../components/FormHeader';
import FormVarTH from '../components/FormVarTH';
import FormMoney from '../components/FormMoney';
import useSubmitPost from '../hooks/handleSubmitPost';
import FormVarF from '../components/FormVarF';
import FormCity from '../components/FormCity';
import FormState from '../components/FormState';
import FormZip from '../components/FormZip';
import FormVarH from '../components/FormVarH';
import FormText from '../components/FormText';
import FormVarTF from '../components/FormVarTF';

const MaintenanceContractNew = () => {
  const navigate = useNavigate();
  const { key, id } = useParams();
  const handleSubmit = useSubmitPost();
  const [contract, setContract] = useState({
    id: '',
    customer_id: '',
    start_year: '',
    contract_type: '',
    job_site_address: '',
    job_site_address2: '',
    job_site_city: '',
    job_site_state: '',
    job_site_zip: '',
    frequency: '',
    hours: '',
    notes: '',
    system_type: '',
    current_price: '',
    exp_date_contract: '',
    status: '',
    terms_begin: '',
    signed_date: '',
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/contracts_crud.php`;

  useEffect(() => {
    const fetchContract = async () => {
      if (key !== 'new') {
        try {
          const response = await axios.get(api, {
            params: { getContractDetail: true, contract_id: id },
          });
          console.log(response.data);
          
          const data = response.data;
          if (data === 'error') {
            toast.error(data.error, {
              position: 'top-center',
            });
          } else {
            setContract(data[1] || {});
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            toast.error('Session expired. Please login again.', {
              position: 'top-center',
            });
            navigate('/login');
          } else if (error.response && error.response.status === 403) {
            navigate('/contracts_list');
            toast.error("You don't have access to this area.", {
              position: 'top-center',
            });
          }
        }
      } else {
        try {
          const response = await axios.get(api, {
            params: {
              getNewContract: true,
              customer_id: id,
            },
          });
          const data = response.data;
          if (data.error) {
            toast.error(data.error, {
              position: 'top-center',
            });
            navigate('/contracts_list');
          } else {
            setContract(data.contract_detail || {});
          }
        } catch (error) {
          console.log('Error fetching data', error);
        }
      }
    };

    fetchContract();
  }, [key, id, api, navigate]);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setContract((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const bodyData = {
    status: 'submit',
    key: key,
    data: {
      ...contract,
    },
  };

  const isSubmitDisabled =
    !contract.customer_id ||
    !contract.job_site_address ||
    !contract.job_site_city ||
    !contract.job_site_state ||
    !contract.frequency;

  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 py-5">
        <form
          onSubmit={(event) =>
            handleSubmit(event, {
              bodyData,
              api,
              errorLabel: 'Contract',
              redirectPath: '/contracts_list',
            })
          }
          className="w-[800px] bg-white border rounded-lg py-8 px-8"
        >
          <div className="space-y-12">
            <FormHeader type={key === 'edit' ? 'Edit' : 'New'} what={'Maintenance Contract'} />

            <div className="border-b border-gray-900/10 pb-12">
              <h6 className="text-base font-semibold leading-7 text-gray-600">
                {key !== 'new' ? (
                  <>
                    <span className="text-gray-700">Contract ID: {id}</span>
                    <br />
                    <span className="text-gray-700">Customer ID: {contract.customer_id}</span>
                  </>
                ) : (
                  <>
                    <span className="text-gray-700">{contract.customer_id}</span>
                  </>
                )}
              </h6>
              <br />

              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                {/* Signed Date */}
                <div className="sm:col-span-2">
                  <label htmlFor="SCHEDULE_DATE" className="block text-sm font-medium text-gray-900 py-1">
                    Signed Date
                  </label>
                  <input
                    type="date"
                    id="signed_date"
                    name="signed_date"
                    value={contract.signed_date || ''}
                    onChange={handleChange}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>                              

                {/* Exp Date */}
                {contract.exp_date_contract === 'auto renew' ? (
                  <FormVarTF label={'Exp Date'} inside={'exp_date_contract'} val={contract.exp_date_contract} onChange={handleChange} />
                ) : (
                  <div className="sm:col-span-2">
                    <label htmlFor="exp_date_contract" className="block text-sm font-medium text-gray-900 py-1">
                      Expiration Date
                    </label>
                    <input
                      type="date"
                      id="exp_date_contract"
                      name="exp_date_contract"
                      value={contract.exp_date_contract || ''}
                      onChange={handleChange}
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div> 
                )}                             

                {/* Start Year */}
                <FormVarTF label={'Start Year'} inside={'start_year'} val={contract.start_year} onChange={handleChange} />

                {/* Contract Type */}
                <FormVarF
                  label={'Contract Type'}
                  inside={'contract_type'}
                  val={contract.contract_type}
                  onChange={handleChange}
                />

                {/* Job Site Address */}
                <FormVarH
                  label={'Job Site Address'}
                  inside={'job_site_address'}
                  val={contract.job_site_address}
                  onChange={handleChange}
                />

                {/* Job Site Address2 */}
                <FormVarH
                  label={'Address2'}
                  inside={'job_site_address2'}
                  val={contract.job_site_address2}
                  onChange={handleChange}
                  required={false}
                />

                {/* Job Site City */}
                <FormCity
                  label={'Job Site City'}
                  inside={'job_site_city'}
                  val={contract.job_site_city}
                  onChange={handleChange}
                />

                {/* Job Site State */}
                <FormState
                  label={'Job Site State'}
                  inside={'job_site_state'}
                  val={contract.job_site_state}
                  onChange={handleChange}
                />

                {/* Job Site Zip */}
                <FormZip
                  label={'Job Site Zip'}
                  inside={'job_site_zip'}
                  val={contract.job_site_zip}
                  onChange={handleChange}
                  type="number"
                />

                {/* Frequency */}
                <FormVarF
                  label={'Frequency'}
                  inside={'frequency'}
                  val={contract.frequency}
                  onChange={handleChange}
                  type="number"
                  required={true}
                />

                {/* Hours */}
                <FormVarF
                  label={'Hours'}
                  inside={'hours'}
                  val={contract.hours}
                  onChange={handleChange}
                  type="number"
                  step="0.01"
                  required={true}
                />

                {/* Notes */}
                <FormText label={'Notes'} inside={'notes'} val={contract.notes} onChange={handleChange} />

                {/* System Type */}
                <FormVarF
                  label={'System Type'}
                  inside={'system_type'}
                  val={contract.system_type}
                  onChange={handleChange}
                />

                {/* Current Price */}
                <FormMoney
                  inside={'current_price'}
                  val={contract.current_price}
                  onChange={handleChange}
                  required={false}
                />

                {/* Terms Begin */}
                <FormVarF
                  label={'Terms Begin'}
                  inside={'terms_begin'}
                  val={contract.terms_begin}
                  onChange={handleChange}
                />

              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <NavLink to={`/maintenance`}>
              <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                Contracts List
              </button>
            </NavLink>
            <SubmitButton disabled={isSubmitDisabled}>Submit</SubmitButton>
          </div>
        </form>
      </div>
    </>
  );
};

export default MaintenanceContractNew;
