import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { toast } from 'react-toastify';
import LoadingSpinner from './LoadingSpinner';
import { TrashIcon, PencilIcon, ArrowUpCircleIcon, } from '@heroicons/react/24/outline';
import DateFormat from './DateFormat';
import Accordion from './Accordion';
import FileLocationCp from './FileLocationCp';
import FileUploadModal from './FileUploadModal';
import HeadingCp from './HeadingCp';

const Tutorial = () => {
  const [tutorials, setTutorials] = useState([]);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null); 
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    subject: '',
    details: '',
    file: '',
    date: '',
  });
  const [editingTutorial, setEditingTutorial] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const [file, setFile] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch tutorials on component mount
  useEffect(() => {
    fetchTutorials();
  }, []);

  const fetchTutorials = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/settings_crud.php`, {
        params: {
          tutorialList: true,
        },
      });
      setTutorials(response.data || []);
    } catch (error) {
      toast.error('Failed to fetch tutorials', {
        position: 'top-center',
      });
    } finally {
      setLoading(false);
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setEditingTutorial(null);
    setFormData({
      subject: '',
      details: '',
      file: '',
      date: '',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    
    try {
      const response = await axios.post(`${apiUrl}/settings_crud.php`, {
        status: 'addTutorial', data: formData });
           console.log(response.data);
      if (response.data.success) {
        toast.success('Tutorial added successfully', {
          position: 'top-center',
        });
        fetchTutorials();
        closeModal();
      } else {
        toast.error('Failed to add tutorial', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An error occurred', {
        position: 'top-center',
      });
    }
  };

  const handleUpdateTutorial = async () => {
    try {
      const response = await axios.post(`${apiUrl}/settings_crud.php`, {
        status: 'updateTutorial',
        id: editingTutorial.id,
        data: formData,
      });
      if (response.data.success) {
        toast.success('Tutorial updated successfully', {
          position: 'top-center',
        });
        fetchTutorials();
        closeModal();
      } else {
        toast.error('Failed to update tutorial', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An error occurred', {
        position: 'top-center',
      });
    }
  };

  const handleDeleteTutorial = async (id, event) => {
    event.stopPropagation();
    console.log(id, event);
    try {
      const response = await axios.post(`${apiUrl}/settings_crud.php`, {
        status: 'deleteTutorial',
        id: id,
      });
      if (response.data.success) {
        toast.success('Tutorial deleted successfully', {
          position: 'top-center',
        });
        fetchTutorials();
      } else {
        toast.error('Failed to delete tutorial', {
          position: 'top-center',
        });
      }
    } catch (error) {
      toast.error('An error occurred', {
        position: 'top-center',
      });
    }
  };

  const toggleAccordion = useCallback(
    (index) => {
      setOpenIndex(openIndex === index ? null : index);
    },
    [openIndex]
  );

   // Handle file selection for upload
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error('No file selected.', { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error('Invalid file type. Only PDF, PNG, and JPEG are allowed.', {
        position: 'top-center',
      });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error('File size exceeds 2MB.', { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };
 
  const handleUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return;
    }
    const formData = new FormData();
    formData.append('status', 'submit_file');
    formData.append('id', selectedFileId); // Include the accounting vendor ID
    formData.append('file', file);
    try {
      const response = await axios.post(`${apiUrl}/tutorial_file.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });

        // Update the state with the new file information
        setTutorials(prev => 
            prev.map(tutorials =>
                tutorials.id === selectedFileId ? 
                { ...tutorials, file: response.data.file } : tutorials
            )
        );

        setFile(null);
        setIsModalFileOpen(false);
      } else {
        toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("An error occurred during file upload.", { position: 'top-center' });
    }
  };  

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className=" mb-6">
        <HeadingCp label={'Tutorials'} />
        <span>
        <button onClick={() => {
            openModal();
            setEditingTutorial(null);
            setFormData({
              subject: '',
              details: ''
            });
          }}
          className="bg-blue-500 text-white px-4 py-2 rounded-md mb-8 float-end"
        >
          Add Tutorial
        </button>
        </span>

      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="mb-8">
          {tutorials.length === 0 ? (
            <p>No tutorials available.</p>
          ) : (
            <div className="mx-auto mt-10">
              {tutorials.map((tutorial, index) => (
                <Accordion
                  key={tutorial.id}
                  title={<p className="font-medium">{tutorial.subject}</p>}
                  content={
                    <div className="p-4">
                      <p className="text-gray-500 mb-2">{tutorial.details}</p>

                      {/* {tutorial.file && (
                        <a
                          href={tutorial.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 underline mb-2 inline-block"
                        >
                          View File
                        </a>
                      )} */}


                      <p className="text-sm text-gray-500 mb-4">
                        Date: <DateFormat date={tutorial.date} />
                      </p>

                      <div className="flex space-x-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditingTutorial(tutorial);
                            setFormData({
                              subject: tutorial.subject,
                              details: tutorial.details
                            });
                            openModal();
                          }}
                          className="text-blue-500 hover:text-blue-700"
                        >
                          <PencilIcon className="h-5 w-5" />
                        </button>

                        <button
                          onClick={(e) => handleDeleteTutorial(tutorial.id, e)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </div>

                      <span className='float-end'>
                          <FileLocationCp FileName={tutorial.file}
                                  where={'news_update_file'}
                                  notFoundButton={<ArrowUpCircleIcon className={`h-5 text-green-600 hover:cursor-pointer hover:text-blue-600`}
                                  onClick={() => { setIsModalFileOpen(true); 
                                                  setSelectedFileId(tutorial.id) }} /> }/>
                      </span>

                    </div>
                  }
                  isOpen={openIndex === index}
                  toggle={() => toggleAccordion(index)}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {/* Modal for Add/Edit Tutorial */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          {/* Overlay */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          {/* Modal Content */}
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              {/* Modal Panel */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {/* Modal Title */}
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {editingTutorial ? 'Edit Tutorial' : 'Add Tutorial'}
                  </Dialog.Title>

                  {/* Form */}
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (editingTutorial) {
                        handleUpdateTutorial();
                      } else {
                        handleSubmit();
                      }
                    }}
                    className="mt-4"
                  >
                    <div className="space-y-4">
                      <div>
                        <label
                          htmlFor="subject"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          value={formData.subject || ''}
                          onChange={handleChange}
                          maxLength="200"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          required
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="details"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Details
                        </label>
                        <textarea
                          name="details"
                          id="details"
                          rows="4"
                          cols="50"
                          value={formData.details || ''}
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          required
                        />
                      </div>
                    </div>

                    {/* Form Buttons */}
                    <div className="mt-6 flex justify-end space-x-2">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
              {/* End of Modal Panel */}
            </div>
          </div>
          {/* End of Modal Content */}
        </Dialog>
      </Transition>
      {/* End of Modal */}

      {/* File Upload Modal */}
      <FileUploadModal
        isOpen={isModalFileOpen}
        onClose={() => setIsModalFileOpen(false)}
        onSubmit={handleUploadFile}
        onFileChange={handleFileChange}
        accept="application/pdf, image/png, image/jpeg" // Accept multiple file types
        title="Upload Reimbursement File"
        label="Select a file (PDF, PNG, JPEG) (Max size 2MB)"/>

    </div>
  );
};

export default Tutorial;
