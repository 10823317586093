import { XMarkIcon } from '@heroicons/react/20/solid';
import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto"> 
      <div className="flex items-center justify-center min-h-screen px-4">
        {/* Background overlay */}
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        {/* Modal content container */}
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-3/4 sm:max-w-lg sm:w-full pb-6">
          {/* Close button */}
          <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <XMarkIcon onClick={onClose} className="h-7 hover:cursor-pointer" />
          </div>
          {/* Modal content */}
          <div className="px-4 py-7 sm:p-6">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
