import React, { useState, useMemo, useEffect } from 'react';
import Modal from '../components/Modal';
import { TrashIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import HeadingCp from '../components/HeadingCp';

const ServicePartList = () => {
  const [parts, setParts] = useState([
    // { id: 1, part: 'Filter', description: 'Oil filter for engine' },
    // { id: 2, part: 'Spark Plug', description: 'Standard spark plug' },
  ]);
  const [searchTerm, setSearchTerm] = useState('');
  const access = 'Admin'; // or 'User'
  const [editMode, setEditMode] = useState(null);
  const [editedPart, setEditedPart] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPart, setNewPart] = useState({
    part: '',
    description: ''
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();


    // Fetch users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?partList=1`, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        });
        if(response.data[0] === 'success')
        {
          setParts(response.data[1]);
        }
        else
        {
          toast.error("missing data.", {
            position: "top-center"
          });
        }
      } catch (error) {
       if (error.response && error.response.status === 401) {
       toast.error("Session expired. Please login again.", {
       position: "top-center"
       });
       navigate('/login');
       } else if (error.response && error.response.status === 403) {
       toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
       position: "top-center"
       });
       }
      }
    };

    fetchUsers();
  }, [apiUrl, navigate]);


  const handleEditPart = (editedPart) => {
    setParts((prevParts) =>
      prevParts.map((part) => (part.id === editedPart.id ? editedPart : part))
    );
    // toast.success('Part updated successfully');
  };

  const handleDeletePart = async(id) => {
    const bodyData = {
      id: id,
      status: 'deletePartList',
    };
      try {
        const response = await axios.post(`${apiUrl}/service_crud.php?`, bodyData, {
              headers: { 'Content-Type': 'application/json' },
        });
        if(response.data[0] === 'success')
        {
            setParts((prevParts) => prevParts.filter((part) => part.id !== id));
            toast.success('Part deleted successfully', {position: "top-center"});
        }
        else
        {
          toast.error("failed deleting part.", {
            position: "top-center"
          });
        }
      } catch (error) {
       if (error.response && error.response.status === 401) {
       toast.error("Session expired. Please login again.", {
       position: "top-center"
       });
       navigate('/login');
       } else if (error.response && error.response.status === 403) {
       toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
       position: "top-center"
       });
       }
      }

  };

  const handleEditClick = (partItem) => {
    setEditMode(partItem.id);
    setEditedPart({ ...partItem });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedPart((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveClick = async() => {
    handleEditPart(editedPart);
    setEditMode(null);

    const bodyData = {
      data: editedPart,
      status: 'updatePartList',
    };
      try {
        const response = await axios.post(`${apiUrl}/service_crud.php?`, bodyData, {
              headers: { 'Content-Type': 'application/json' },
        });
        if(response.data[0] === 'success')
        {
           toast.success('Part list updated successfully');
        }
        else
        {
          toast.error("missing data.", {
            position: "top-center"
          });
        }
      } catch (error) {
       if (error.response && error.response.status === 401) {
       toast.error("Session expired. Please login again.", {
       position: "top-center"
       });
       navigate('/login');
       } else if (error.response && error.response.status === 403) {
       toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
       position: "top-center"
       });
       }
      }
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewPart((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddClick = async() => {
    // handleAddPart(newPart);
    setNewPart({
      part: '',
      description: ''
    });
    setIsModalOpen(false);

    const bodyData = {
      data: newPart,
      status: 'updatePartList',
    };
      try {
        const response = await axios.post(`${apiUrl}/service_crud.php?`, bodyData, {
              headers: { 'Content-Type': 'application/json' },
        });
        if(response.data[0] === 'success')
        {
          setParts([...parts, { id: response.data[1].id, ...newPart }]);
           toast.success('Added new part successfully');
        }
        else
        {
          toast.error("Failed adding part.", {
            position: "top-center"
          });
        }
      } catch (error) {
       if (error.response && error.response.status === 401) {
       toast.error("Session expired. Please login again.", {
       position: "top-center"
       });
       navigate('/login');
       } else if (error.response && error.response.status === 403) {
       toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
       position: "top-center"
       });
       }
      }

  };

  const filteredParts = useMemo(() => {
    return parts.filter(partItem =>
      partItem.part.toLowerCase().includes(searchTerm.toLowerCase()) ||
      partItem.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [parts, searchTerm]);


  return (
    <>
      {/* Search Input */}
      <div className="mx-auto max-w-[1250px] px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-2xl font-semibold text-gray-500 mb-8">
          <HeadingCp label={'Service Part List'} />
        </h1>
        <br />
          <div className={`sm:flex sm:items-left ${filteredParts.length > 0 ? '' : 'float-left'}`}>
            <div className={`sm:flex-auto ${filteredParts.length > 0 ? '' : 'hidden'}`}>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm
                          hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
                          focus-visible:outline-indigo-600"
                onClick={() => setIsModalOpen(true)}>
                Add Service Part
              </button>
            </div>
          </div>
          <br/>

          <div className="flex items-center space-x-4 mb-1">
            <NavLink to="/service_new/new">
              <button
                type="button"
                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                  shadow-sm text-white bg-orange-400 hover:bg-orange-700 focus:outline-none 
                  focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                New Service Call +
              </button>
            </NavLink>

            <NavLink to="/service_dashboard">
              <button
                type="button"
                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                  shadow-sm text-white bg-green-400 hover:bg-green-700 focus:outline-none 
                  focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Service Dashboard
              </button>
            </NavLink>

            <NavLink to="/service_cal">
              <button
                type="button"
                className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
                  shadow-sm text-white bg-green-400 hover:bg-green-700 focus:outline-none 
                  focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Cal View
              </button>
            </NavLink>

            <input
              type="text"
              placeholder="Search parts"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-2 border border-gray-300 rounded-md flex-grow"
            />
          </div>

          <br/>
          {filteredParts.length > 0 ? (
            <>
              <div className="px-4 sm:px-6 mt-2 lg:px-8 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle">
                    <table className="min-w-full max-w-2xl mx-auto divide-y divide-gray-300 border">
                      <thead>
                        <tr>
                          <th scope="col" className="py-3.5 bg-gray-200 pl-4 pr-3 text-center border border-gray-300 text-sm font-semibold text-gray-900 sm:pl-0">
                            ID
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900 sm:table-cell"
                          >
                            Part
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900"
                          >
                            Description
                          </th>
                          {access === 'Admin' && (
                            <th
                              scope="col"
                              className="px-3 py-3.5 bg-gray-200 text-left border border-gray-300 text-sm font-semibold text-gray-900"
                            >
                              Actions
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {filteredParts.map((partItem, index) => (
                          <tr key={partItem.id} className={`divide-x divide-gray-200 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                            {editMode === partItem.id ? (
                              <>
                                <td className="py-4 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                                  <TrashIcon className='h-5 text-red-600 hover:cursor-pointer' onClick={() => handleDeletePart(partItem.id)} />
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                  <input
                                    type="text"
                                    name="part"
                                    value={editedPart.part || ''}
                                    onChange={handleInputChange}
                                    className="border-gray-300 rounded-md w-full"
                                  />
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                  <textarea
                                    name="description"
                                    value={editedPart.description || ''}
                                    onChange={handleInputChange}
                                    className="border-gray-300 rounded-md w-full"
                                    rows="2"
                                  />
                                </td>
                                {access === 'Admin' && (
                                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium">
                                    <button
                                      onClick={() => handleSaveClick(editedPart.id)}
                                      className="text-indigo-600 hover:text-indigo-900"
                                    >
                                      Save
                                    </button>
                                  </td>
                                )}
                              </>
                            ) : (
                              <>
                                <td className="py-4 px-3 text-sm font-medium text-gray-900 sm:pl-3">
                                  {partItem.id}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                  {partItem.part}
                                </td>
                                <td className=" px-3 py-4 text-sm text-gray-500">
                                  {partItem.description}
                                </td>
                                {access === 'Admin' && (
                                  <td className=" py-4 pl-3 pr-4 text-right text-sm font-medium">
                                    <button
                                      onClick={() => handleEditClick(partItem)}
                                      className="text-indigo-600 hover:text-indigo-900"
                                    >
                                      Edit
                                    </button>
                                  </td>
                                )}
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p className="text-center mt-4">No service parts found.</p>
          )}
      </div>


      {/* Modal for adding new service part */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-lg font-semibold text-gray-900">Add New Service Part</h2>
        <form>
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Part</label>
            <input
              type="text"
              name="part"
              value={newPart.part}
              required={true}
              onChange={handleNewInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              name="description"
              value={newPart.description}
              onChange={handleNewInputChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              rows="3"
            ></textarea>
          </div>

          <div className="mt-6">
            <button
              type="button"
              disabled={!newPart.part}
              onClick={handleAddClick}
              className={`inline-flex justify-center rounded-md border border-transparent float-right mt-9
                      ${!newPart.part ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'}
                      py-2 px-4 text-sm font-medium text-white shadow-sm 
                      focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Add
            </button>

          </div>
        </form>
      </Modal>
    </>
  );
};

export default ServicePartList;
