import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import SubMenu from '../components/SubMenu';
import DateFormat from '../components/DateFormat';
import SearchBarCP from '../components/SearchBarCP';
import Modal from '../components/Modal';
import ModalWindow from '../components/ModalWindow';
import ModalNotesCp from '../components/ModalNotesCp';
import { useUserData } from '../context/UserData';
import FormHeader from '../components/FormHeader';
import FormVarH from '../components/FormVarH';
import SubmitButton from '../components/SubmitButton';
import LoadingSpinner from '../components/LoadingSpinner';
import COTtopTabs from '../components/COTtopTabs.js';
import CurrencyFormat from '../components/CurrencyFormat';
import PhoneNumber from '../components/PhoneNumber.js';
import FileLocationCp from '../components/FileLocationCp';
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import Truncate from '../components/Truncate';
import FileUploadModal from '../components/FileUploadModal';
import { Dialog, Transition } from '@headlessui/react';
import SelectCustomers from '../components/SelectCustomers';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CotBillingPage = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/cot_billing_contracts.php`;
  const [newContract, setNewContract] = useState(false);
  const [newContractData, setNewContractData] = useState({
    customer_id: '',
    customer_name: '',
    contract_address: '',
    contract_account_number: '',
    contact_name: '',
    contact_phone: '',
    contact_ext: '',
    contact_email: '',
    contract_price: '',
    contract_signed: '',
    frequency: '6', // Default to Semi-Annual
    file: null,
    notes: '',
  });
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  const [selectedContractId, setSelectedContractId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [notesIdOpen, setNotesIdOpen] = useState(null);
  const userData = useUserData();
  const [count, setCount] = useState(0);
  const [selectedFileId, setSelectedFileId] = useState(null);

  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // 0 (January) to 11 (December)
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // New state variables for editing contracts
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editContractData, setEditContractData] = useState(null);

  const handleMonthClick = (monthIndex) => {
    setCurrentMonth(monthIndex);
    fetchContractsForMonth(monthIndex);
  };

  const fetchContractsForMonth = async (monthIndex) => {
    setLoading(true);
    let url = `${apiUrl}/cot_crud.php?getContracts=true`;

    if (monthIndex !== -1) {
      const month = monthIndex + 1; // JavaScript months are 0-indexed, but backend might expect 1-12
      url += `&month=${month}`;
    } else {
      url += `&all=true`;
    }

    try {
      const response = await axios.get(url, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
      });
      const contractsData = Array.isArray(response.data[0]) ? response.data[0] : [];
      const count = response.data[1] > 0 ? response.data[1] : 0;

      if (contractsData.length === 0) {
        toast.info(`No contracts found.`, { position: "top-center" });
      }
      setFilteredContracts(contractsData);
      setContracts(contractsData);
      setCount(count);

    } catch (error) {
      toast.error("Error fetching data.", { position: "top-center" });
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Access denied. Speak to the administrator.", { position: "top-center" });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNotesView = (id) => {
    setNotesIdOpen(id);
  };

  // Generate month tabs, including 'All'
  const monthTabs = [
    {
      name: 'All',
      current: currentMonth === -1,
      onClick: () => handleMonthClick(-1),
    },
    ...Array.from({ length: 12 }, (_, index) => {
      const date = new Date(0, index);
      const monthName = date.toLocaleString('default', { month: 'short' }); // 'Jan', 'Feb', etc.
      return {
        name: monthName,
        current: index === currentMonth,
        onClick: () => handleMonthClick(index),
      };
    }),
  ];

  useEffect(() => {
    fetchContractsForMonth(currentMonth);
  }, [currentMonth, apiUrl, navigate]); 

  const [bodyDataNotes, setBodyDataNotes] = useState({
    status: 'submit_cot_notes',
    employee_id: null,
    data: {},
  });

  const handleFormSubmit = async (file, contractId) => {
    if (!file) {
      toast.error("Please upload a valid file.", { position: 'top-center' });
      return;
    }
    const formData = new FormData();
    formData.append('status', 'upload_contract_file');
    formData.append('id', contractId);
    formData.append('file', file);
    try {
      const response = await axios.post(`${apiUrl}/cot_billing_contracts.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setFilteredContracts(prev => prev.map(item => {
        if (item.id === parseInt(response.data.id)) {
          return { ...item, file: response.data.file };
        }
        return item;
      }));

      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });
      } else {
        toast.error(response.data.error, { position: 'top-center' });
      }
    } catch (error) {
      toast.error("Error uploading file.", { position: "top-center" });
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", { position: "top-center" });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Access denied. Speak to the administrator.", { position: "top-center" });
      }
    }
  };

  const getLinksForContract = (contract) => {
    let links = [
      { name: 'Edit Contract', click: () => editContract(contract.id), key: `edit-${contract.id}` },
      {
        name: contract.file ? 'Update Contract File' : 'Upload Contract File',
        click: () => { setIsModalFileOpen(true); setSelectedContractId(contract.id) },
        key: `upload-file-${contract.id}`
      },
      // { name: 'Delete Contract', click: () => handleDelete(contract.id), key: `delete-contract-${contract.id}` },
    ];
    return links;
  };

  const toggleContractStatus = async (id, status) => {
    setLoading(true);
    const bodyData = {
      status: 'update_contract_status',
      data: { contract_id: id, contract_status: status }
    };

    try {
      const response = await axios.post(`${apiUrl}/cot_billing_contracts.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        setFilteredContracts(objects => objects.map(item => {
          if (item.id === id) {
            return { ...item, status: status };
          }
          return item;
        }));

        toast.success('Status updated!', { position: 'top-center' });
      } else {
        toast.error('Error updating status', { position: 'top-center' });
      }
    } catch (error) {
      console.error('Error updating status', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, id, user) => {
    const { name, value } = event.target;
    setFilteredContracts(prev => prev.map(item => {
      if (parseInt(item.id) === id) {
        const updatedContract = { ...item, [name]: value };
        bodyDataNotes.data = updatedContract;
        updatedContract.employee_name = `${user.first_name} ${user.last_name}`;
        return updatedContract;
      } else {
        return item;
      }
    }));
    setBodyDataNotes(prev => ({
      ...prev,
      employee_id: user.id,
      data: { ...prev.data, [name]: value }
    }));
  };

  const handleSearchClick = async () => {
    if (!searchTerm) {
      toast.info('Search bar is empty', { position: 'top-center' });
    } else {
      setLoading(true);

      const bodyData = {
        status: 'search',
        data: { search: searchTerm }
      };
      try {
        const response = await axios.post(`${apiUrl}/cot_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' }
        });
        if (response.data && Array.isArray(response.data)) {
          setContracts(response.data);
          setFilteredContracts(response.data);
        } else {
          handleSearchChange({ target: { value: '' } });
          setContracts([]);
          setFilteredContracts([]);
          toast.error(`Not found: ${searchTerm}`, { position: 'top-center' });
        }
      } catch (error) {
        console.error('Error searching contracts', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === '') {
      setFilteredContracts(contracts);
    } else {
      const filtered = contracts.filter(contract =>
        (contract.contract_account_number && contract.contract_account_number.toLowerCase().includes(value.toLowerCase())) ||
        (contract.contact_name && contract.contact_name.toLowerCase().includes(value.toLowerCase())) ||
        (contract.customer_name && contract.customer_name.toLowerCase().includes(value.toLowerCase())) ||
        (contract.contract_address && contract.contract_address.toLowerCase().includes(value.toLowerCase()))
      );
      setFilteredContracts(filtered);
    }
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewContractData((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewContract = async (e) => {
    e.preventDefault();
    if (newContractData.contract_account_number === '') {
      toast.error("Contract account number is required", { position: "top-center" });
      return;
    }
    const bodyData = {
      data: newContractData,
      status: 'submit_new_contract'
    };
    try {
      const response = await axios.post(`${apiUrl}/cot_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success("Contract successfully saved!", { position: "top-center" });
        setFilteredContracts((prev) => [response.data[0], ...prev]);
        setContracts((prev) => [response.data[0], ...prev]);
        setNewContract(false);
      } else {
        toast.error(response.data.error || "Failed to save contract.", { position: "top-center" });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", { position: "top-center" });
    }
  };

  const handleDelete = async (contractId) => {
    try {
      const response = await axios.post(`${apiUrl}/cot_billing_contracts.php`, {
        status: 'delete_contract',
        data: { contract_id: contractId },
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data.success) {
        toast.success("Contract deleted successfully!", {
          position: "top-center"
        });
        setFilteredContracts(prev => prev.filter(item => item.id !== contractId));
        setContracts(prev => prev.filter(item => item.id !== contractId));
      } else {
        toast.error(response.data.message || "Failed to delete contract!", {
          position: "top-center"
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.", {
        position: "top-center"
      });
    }
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return;
    }
    const formData = new FormData();
    formData.append('status', 'submit_file');
    formData.append('id', selectedFileId); // Include the contract ID
    formData.append('file', file);
    
    try {
      const response = await axios.post(`${apiUrl}/cot_file_upload.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });
        setFilteredContracts(prev => 
          prev.map(contract =>
            contract.id === selectedFileId ? 
            { ...contract, file: response.data.cot_billing.file } : contract
          )
        );
        setContracts(prev => 
          prev.map(contract =>
            contract.id === selectedFileId ? 
            { ...contract, file: response.data.cot_billing.file } : contract
          )
        );        
        setFile(null);
        setIsModalFileOpen(false);
      } else {
        toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("An error occurred during file upload.", { position: 'top-center' });
    }
  };

   // Handle file selection for upload
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error('No file selected.', { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf', 'image/png', 'image/jpeg'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error('Invalid file type. Only PDF, PNG, and JPEG are allowed.', {
        position: 'top-center',
      });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error('File size exceeds 2MB.', { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };  

  const handleCompanySelect = (newCompany) => {
    if (newCompany && newCompany.customer_name) {
      setSelectedCompany(newCompany);
      setNewContractData(prev => ({
        ...prev,
        customer_name: newCompany.customer_name,
        customer_id: newCompany.id
      }));    
    } else {
      console.error('Invalid company selected:', newCompany);
    }
  };

  const editContract = (contractId) => {
    const contractToEdit = contracts.find((contract) => contract.id === contractId);

    // Ensure no null values in contractToEdit
    const contractWithDefaults = {
      ...contractToEdit,
      contract_address: contractToEdit.contract_address || '',
      contract_account_number: contractToEdit.contract_account_number || '',
      contact_name: contractToEdit.contact_name || '',
      contact_phone: contractToEdit.contact_phone || '',
      contact_ext: contractToEdit.contact_ext || '',
      contact_email: contractToEdit.contact_email || '',
      contract_price: contractToEdit.contract_price !== null ? contractToEdit.contract_price : '',
      contract_signed: contractToEdit.contract_signed || '',
      frequency: contractToEdit.frequency !== null ? contractToEdit.frequency.toString() : '',
      notes: contractToEdit.notes || '',
      customer_id: contractToEdit.customer_id !== null ? contractToEdit.customer_id : '',
      customer_name: contractToEdit.customer_name || '',
    };

    setEditContractData(contractWithDefaults);
    setIsEditModalOpen(true);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditContractData((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditCompanySelect = (newCompany) => {
    if (newCompany && newCompany.customer_name) {
      setEditContractData((prev) => ({
        ...prev,
        customer_name: newCompany.customer_name,
        customer_id: newCompany.id,
      }));
    } else {
      console.error('Invalid company selected:', newCompany);
    }
  };

  const handleEditContractSubmit = async (e) => {
    e.preventDefault();

    // Prepare the data to send to the backend
    const bodyData = {
      status: 'submit_new_contract',
      data: editContractData,
    };
    try {
      const response = await axios.post(`${apiUrl}/cot_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data.success) {
        toast.success('Contract updated successfully!', { position: 'top-center' });

        // Update the contracts and filteredContracts state with the updated contract
        setContracts((prevContracts) =>
          prevContracts.map((contract) =>
            contract.id === editContractData.id ? editContractData : contract
          )
        );
        setFilteredContracts((prevContracts) =>
          prevContracts.map((contract) =>
            contract.id === editContractData.id ? editContractData : contract
          )
        );

        // Close the edit modal
        setIsEditModalOpen(false);
      } else {
        toast.error(response.data.error || 'Failed to update contract.', { position: 'top-center' });
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.', { position: 'top-center' });
      console.error('Error updating contract:', error);
    }
  };

  return (
    <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 py-10">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900 py-4">COT Billing Contracts</h1>
        </div>
      </div>

      <COTtopTabs tabs={monthTabs} />

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">

          <div className="sm:flex sm:items-center py-6">
            <div className="flex w-full items-center mt-5 sm:mt-0">
              <button
                type="button"
                onClick={() => setNewContract(true)}
                className="mr-2 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
              >
                Add Contract
              </button>

              {/* New Contract Modal */}
              <Transition appear show={newContract} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setNewContract(false)}>
                  {/* Backdrop */}
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                {/* Modal Content */}
                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          {/* Modal Title */}
                          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                            New Contract
                          </Dialog.Title>

                          {/* Modal Form */}
                          <form onSubmit={handleNewContract} className="mt-4">
                            <div className="space-y-4">
                              {/* Contract Address */}
                              <div>
                                <label htmlFor="contract_address" className="block text-sm font-medium text-gray-700">
                                  Contract Site
                                </label>
                                <input
                                  type="text"
                                  name="contract_address"
                                  id="contract_address"
                                  value={newContractData.contract_address}
                                  onChange={handleNewInputChange}
                                  maxLength="250"
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  required
                                />
                              </div>
                                
                                <div className="flex space-x-4">
                                {/* Select a Company */}                                
                                <div className="flex-1">
                                  <SelectCustomers
                                    label="Select a Company"
                                    items={companies}
                                    selectedItem={selectedCompany}
                                    setSelectedItem={handleCompanySelect}
                                    apiUrl={`${apiUrl}/customer_crud.php`}
                                  />
                                </div>                                

                                {/* Contract Account Number */}
                                <div className='w-32 mt-2'>
                                  <label htmlFor="contract_account_number" className="block text-sm font-medium text-gray-700">
                                    Account#
                                  </label>
                                  <input
                                    type="text"
                                    name="contract_account_number"
                                    id="contract_account_number"
                                    value={newContractData.contract_account_number}
                                    onChange={handleNewInputChange}
                                    maxLength="150"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>

                              {/* Contact Name */}
                              <div>
                                <label htmlFor="contact_name" className="block text-sm font-medium text-gray-700">
                                  Contact Name
                                </label>
                                <input
                                  type="text"
                                  name="contact_name"
                                  id="contact_name"
                                  value={newContractData.contact_name}
                                  onChange={handleNewInputChange}
                                  maxLength="200"
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>

                              {/* Contact Phone and Extension */}
                              <div className="flex space-x-4">
                                {/* Contact Phone */}
                                <div className="flex-1">
                                  <label htmlFor="contact_phone" className="block text-sm font-medium text-gray-700">
                                    Contact Phone
                                  </label>
                                  <input
                                    type="text"
                                    name="contact_phone"
                                    id="contact_phone"
                                    value={newContractData.contact_phone}
                                    onChange={handleNewInputChange}
                                    maxLength="20"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>

                                {/* Contact Ext */}
                                <div className="w-24">
                                  <label htmlFor="contact_ext" className="block text-sm font-medium text-gray-700">
                                    Ext
                                  </label>
                                  <input
                                    type="number"
                                    name="contact_ext"
                                    id="contact_ext"
                                    value={newContractData.contact_ext}
                                    onChange={handleNewInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>

                              {/* Contact Email */}
                              <div>
                                <label htmlFor="contact_email" className="block text-sm font-medium text-gray-700">
                                  Contact Email
                                </label>
                                <input
                                  type="email"
                                  name="contact_email"
                                  id="contact_email"
                                  value={newContractData.contact_email}
                                  onChange={handleNewInputChange}
                                  maxLength="250"
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>

                              {/* Contract Price */}
                              <div>
                                <label htmlFor="contract_price" className="block text-sm font-medium text-gray-700">
                                  Contract Price
                                </label>
                                <input
                                  type="number"
                                  name="contract_price"
                                  id="contract_price"
                                  value={newContractData.contract_price}
                                  onChange={handleNewInputChange}
                                  step="0.01"
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  required
                                />
                              </div>

                              {/* Contract Signed Date and Frequency */}
                              <div className="flex space-x-4">
                                {/* Contract Signed */}
                                <div className="w-34">
                                  <label htmlFor="contract_signed" className="block text-sm font-medium text-gray-700">
                                    Contract Signed Date
                                  </label>
                                  <input
                                    type="date"
                                    name="contract_signed"
                                    id="contract_signed"
                                    value={newContractData.contract_signed}
                                    onChange={handleNewInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                  />
                                </div>

                                <div className="flex-1">
                                  <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">
                                    Frequency
                                  </label>
                                  <select
                                    name="frequency"
                                    id="frequency"
                                    value={newContractData.frequency}
                                    onChange={handleNewInputChange}
                                    className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    required
                                  >
                                    <option value="6">Semi-Annual</option>
                                    <option value="12">Annually</option>
                                    <option value="3">Quarterly</option>
                                    <option value="1">Monthly</option>
                                  </select>
                                </div>
                                </div>

                              {/* Notes */}
                              <div>
                                <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                                  Notes
                                </label>
                                <textarea
                                  name="notes"
                                  id="notes"
                                  value={newContractData.notes}
                                  onChange={handleNewInputChange}
                                  maxLength="65535"
                                  rows={3}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                ></textarea>
                              </div>

                            </div>

                            {/* Form Buttons */}
                            <div className="mt-6 flex justify-end space-x-2">
                              <button
                                type="button"
                                className="inline-flex justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-400 focus:outline-none"
                                onClick={() => setNewContract(false)}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="inline-flex justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none"
                              >
                                Save
                              </button>
                            </div>
                          </form>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>

              <div className="flex-grow">
                <SearchBarCP search={searchTerm} handleChange={handleSearchChange} onClick={handleSearchClick} />
              </div>
            </div>
          </div>

          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <LoadingSpinner>Loading...</LoadingSpinner>
            ) : (
              <div className="shadow ring-1 ring-black ring-opacity-5">
                <table className="min-w-full border-separate border-spacing-0">
                  <thead className="top-0 z-30 bg-white">
                    <tr>
                      <th className="border-b border-gray-300 bg-white py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                        ID/Edit
                      </th>
                      <th className="border-b border-gray-300 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Account# | Signed Date
                      </th>
                      <th className="border-b border-gray-300 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Customer
                      </th>
                      <th className="border-b border-gray-300 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Contact
                      </th>
                      <th className="border-b border-gray-300 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Contract Price
                      </th>
                      <th className="border-b border-gray-300 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        File
                      </th>
                      <th className="border-b border-gray-300 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Next Due Date
                      </th>
                      <th className="border-b border-gray-300 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Notes
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredContracts.map((contract, index) => (
                      <tr key={contract.id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} divide-x`}>
                        <td
                          className={classNames(
                            index !== filteredContracts.length - 1 ? 'border-b border-gray-200' : '',
                            'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900'
                          )}
                        >
                        <SubMenu label={contract.id} idItem={contract.id} links={getLinksForContract(contract)} />
                        </td>

                        <td className="px-3 py-4 text-sm text-gray-700">
                          <span className='font-semibold'>{contract.contract_account_number}</span><br/>
                          <span className='text-blue-600'>
                          {contract.contract_signed && (
                            <DateFormat date={contract.contract_signed} />
                          )}
                          </span>
                        </td>

                        <td className="px-3 py-4 text-sm text-gray-700">
                          <span className='font-semibold text-green-700'>{contract.customer_name} </span><br />
                          {contract.contract_address}
                        </td>

                        <td className="px-3 py-4 text-sm text-gray-700">
                          {contract.contact_name} 
                          <br/>
                          {contract.contact_phone && (
                            <>
                              <span className='text-xs'>
                              <PhoneNumber number={contract.contact_phone} /> {contract.contact_ext && ` ext. ${contract.contact_ext}`}
                              </span>
                              <br/>
                            </>
                          )}

                          <span className='text-xs'>
                            {contract.contact_email}
                          </span>
                        </td>

                        <td className="px-3 py-4 text-sm text-gray-700">
                          <CurrencyFormat value={contract.contract_price} />
                        </td>

                        <td className="px-3 py-4 text-sm text-gray-700">
                          <FileLocationCp FileName={contract.file}
                            where={'cot_file_contract'}
                            notFoundButton={<ArrowUpCircleIcon className={`h-5 text-green-600 hover:cursor-pointer hover:text-blue-600`}
                            onClick={() => { setIsModalFileOpen(true); 
                                            setSelectedFileId(contract.id) }} /> }/>
                        </td>

                        <td className="px-3 py-4 text-sm text-gray-700">
                          <DateFormat date={contract.next_due_date} />
                        </td>

                        <td className="px-3 py-4 text-sm text-gray-700">
                          {contract.notes && 
                          <Truncate text={contract.notes} handleNotesView={() => handleNotesView(contract.id)} />
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

        </div>
      </div>

      {/* Edit Contract Modal */}
      {isEditModalOpen && (
        <Transition appear show={isEditModalOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => setIsEditModalOpen(false)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Edit Contract
                    </Dialog.Title>
                    {editContractData && (
                      <form onSubmit={handleEditContractSubmit} className="mt-4">
                        <div className="space-y-4">
                          {/* Contract Address */}
                          <div>
                            <label htmlFor="contract_address" className="block text-sm font-medium text-gray-700">
                              Contract Site
                            </label>
                            <input
                              type="text"
                              name="contract_address"
                              id="contract_address"
                              value={editContractData.contract_address}
                              onChange={handleEditInputChange}
                              maxLength="250"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              required
                            />
                          </div>

                          <div className="flex space-x-4">
                            {/* Select a Company */}                                
                            <div className="flex-1">
                              <SelectCustomers
                                label="Select a Company"
                                items={companies}
                                selectedItem={{ id: editContractData.customer_id, customer_name: editContractData.customer_name }}
                                setSelectedItem={handleEditCompanySelect}
                                apiUrl={`${apiUrl}/customer_crud.php`}
                              />
                            </div>                                

                            {/* Contract Account Number */}
                            <div className='w-32 mt-2'>
                              <label htmlFor="contract_account_number" className="block text-sm font-medium text-gray-700">
                                Account#
                              </label>
                              <input
                                type="text"
                                name="contract_account_number"
                                id="contract_account_number"
                                value={editContractData.contract_account_number}
                                onChange={handleEditInputChange}
                                maxLength="150"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* Contact Name */}
                          <div>
                            <label htmlFor="contact_name" className="block text-sm font-medium text-gray-700">
                              Contact Name
                            </label>
                            <input
                              type="text"
                              name="contact_name"
                              id="contact_name"
                              value={editContractData.contact_name}
                              onChange={handleEditInputChange}
                              maxLength="200"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>

                          {/* Contact Phone and Extension */}
                          <div className="flex space-x-4">
                            {/* Contact Phone */}
                            <div className="flex-1">
                              <label htmlFor="contact_phone" className="block text-sm font-medium text-gray-700">
                                Contact Phone
                              </label>
                              <input
                                type="text"
                                name="contact_phone"
                                id="contact_phone"
                                value={editContractData.contact_phone}
                                onChange={handleEditInputChange}
                                maxLength="20"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>

                            {/* Contact Ext */}
                            <div className="w-24">
                              <label htmlFor="contact_ext" className="block text-sm font-medium text-gray-700">
                                Ext
                              </label>
                              <input
                                type="number"
                                name="contact_ext"
                                id="contact_ext"
                                value={editContractData.contact_ext}
                                onChange={handleEditInputChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {/* Contact Email */}
                          <div>
                            <label htmlFor="contact_email" className="block text-sm font-medium text-gray-700">
                              Contact Email
                            </label>
                            <input
                              type="email"
                              name="contact_email"
                              id="contact_email"
                              value={editContractData.contact_email}
                              onChange={handleEditInputChange}
                              maxLength="250"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>

                          {/* Contract Price */}
                          <div>
                            <label htmlFor="contract_price" className="block text-sm font-medium text-gray-700">
                              Contract Price
                            </label>
                            <input
                              type="number"
                              name="contract_price"
                              id="contract_price"
                              value={editContractData.contract_price}
                              onChange={handleEditInputChange}
                              step="0.01"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              required
                            />
                          </div>

                          {/* Contract Signed Date and Frequency */}
                          <div className="flex space-x-4">
                            {/* Contract Signed */}
                            <div className="w-34">
                              <label htmlFor="contract_signed" className="block text-sm font-medium text-gray-700">
                                Contract Signed Date
                              </label>
                              <input
                                type="date"
                                name="contract_signed"
                                id="contract_signed"
                                value={editContractData.contract_signed}
                                onChange={handleEditInputChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                required
                              />
                            </div>

                            <div className="flex-1">
                              <label htmlFor="frequency" className="block text-sm font-medium text-gray-700">
                                Frequency
                              </label>
                              <select
                                name="frequency"
                                id="frequency"
                                value={editContractData.frequency}
                                onChange={handleEditInputChange}
                                className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                required
                              >
                                <option value="6">Semi-Annual</option>
                                <option value="12">Annually</option>
                                <option value="3">Quarterly</option>
                                <option value="1">Monthly</option>
                              </select>
                            </div>
                          </div>

                          {/* Notes */}
                          <div>
                            <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                              Notes
                            </label>
                            <textarea
                              name="notes"
                              id="notes"
                              value={editContractData.notes}
                              onChange={handleEditInputChange}
                              maxLength="65535"
                              rows={3}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            ></textarea>
                          </div>
                        </div>
                        <div className="mt-6 flex justify-end space-x-2">
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md bg-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-400 focus:outline-none"
                            onClick={() => setIsEditModalOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none"
                          >
                            Save Changes
                          </button>
                        </div>
                      </form>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}

      {/* File Upload Modal */}
      <FileUploadModal
        isOpen={isModalFileOpen}
        onClose={() => setIsModalFileOpen(false)}
        onSubmit={handleUploadFile}
        onFileChange={handleFileChange}
        accept="application/pdf, image/png, image/jpeg" // Accept multiple file types
        title="Upload Contract File"
        label="Select a file (PDF, PNG, JPEG) (Max size 2MB)"
      />

    </div>
  );
}

export default CotBillingPage;