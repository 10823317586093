import React, { useState, useEffect } from 'react';

import SubmitButton from '../components/SubmitButton';
import SearchBarCP from '../components/SearchBarCP';
import LoadingSpinner from '../components/LoadingSpinner';
import SubMenu from '../components/SubMenu';
import DateFormat from '../components/DateFormat';
import ModalNotesCp from '../components/ModalNotesCp';
import { toast } from 'react-toastify';
import FileLocation from '../components/FileLocation';
import { useUserData } from '../context/UserData';
import Modal from '../components/Modal';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from 'react-router-dom';
import PhoneNumber from '../components/PhoneNumber';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import HeadingCp from '../components/HeadingCp';
import LegendCP from '../components/LegendCP';
import ServiceInvoiceFileLocation from '../components/ServiceInvoiceFileLocation';
import ServiceGenFileLocation from '../components/ServiceGenFileLocation';
import { DocumentCheckIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import SelectEmployeeCp from '../components/SelectEmployeeCp';
import Truncate from '../components/Truncate';
import ModalWindow from '../components/ModalWindow';
import { TrashIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ServiceCallList = () => {

  const [notesIdOpen, setNotesIdOpen] = useState(null); 

  //for the modal popup
  const [additionTechLink, setAdditionTechLink] = useState([]);

  const [addTech, setAddTech] = useState({additional_tech:''});
  const [selectedService, setSelectedService] = useState(null);
  const [additionTech, setAdditionTech] = useState([]);

  const [selectEmployee, setSelectedEmployee] = useState(false);

  const [isModalOpenLead, setIsModalOpenLead] = useState(null)

  const apiUrl = process.env.REACT_APP_API_URL;
  const api = `${apiUrl}/service_crud.php`;
  const userData = useUserData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedServiceCall, setSelectedServiceCall] = useState(null);
  const [serviceCallForm, setServiceCallForm] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [fetchItems, setFetchItems] = useState([]);
  const [filterStatus, setFilterStatus] = useState('PENDING');
  const [filterEmployee, setFilterEmployee] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [editingTech, setEditingTech] = useState({}); // To track which row is being edited
  
  const [file, setFile] = useState(null);
  
  const [selectInvoiceFile, setSelectInvoiceFile] = useState(null);
  const [isModalFileOpen, setIsModalFileOpen] = useState(false);
  
  const [selectGenFile, setSelectGenFile] = useState(null);
  const [isModalFileGenOpen, setIsModalFileGenOpen] = useState(false);
  

  useEffect(() => {
    setLoading(true);
    const fetchServiceCalls = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?getList=1`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        console.log(response.data);
        
        const serviceCalls = Array.isArray(response.data) ? response.data : [];
        if (serviceCalls.length === 0) {
          toast.error("No service calls found!", { position: "top-center" });
        }
        setFilterItems(serviceCalls);
        setFetchItems(serviceCalls);
          
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchServiceCalls();
  }, [apiUrl, navigate]);

  useEffect(() => {
    setLoading(true);
    const employees = async () => {
      try {
        const response = await axios.get(`${apiUrl}/service_crud.php?employee_list=true`, {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        });
        const serviceCalls = Array.isArray(response.data) ? response.data : [];
        if (serviceCalls.length === 0) {
          toast.error("No service calls found!", { position: "top-center" });
        }
        setEmployees(response.data);
        
        //addition tech
        const additionTech = Array.isArray(response.data.add_tech) ? response.data.add_tech : [];
        setAdditionTech(additionTech || [])

      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", { position: "top-center" });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", { position: "top-center" });
        }
      } finally {
        setLoading(false);
      }
    };

    employees();
  }, [apiUrl, navigate]); 

  useEffect(() => {
    const filtered = filterStatus === 'ALL'  ? fetchItems  : filterStatus === 'READY'
      ? fetchItems.filter(item => item.INVOICE === filterStatus) // Filter by INVOICE if filterStatus is READY
      : filterStatus === 'ASSIGNED' 
      ? fetchItems.filter(item => item.STATUS === 'ASSIGNED' || item.STATUS === 'ACTIVATED') // Filter for ASSIGNED or ACTIVATED
      : fetchItems.filter(item => item.STATUS === filterStatus);  // Otherwise, filter by STATUS

    setFilterItems(filtered);
  }, [filterStatus, fetchItems]);

  const handleNotesView = (id) => {
    setNotesIdOpen(id);
  };

  const handleTechFilter = async (techName) => {

    const bodyData = {
      status: "filterTech",
      techName: techName,
    }
      setLoading(true)
      try {
        const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
          headers: { 'Content-Type': 'application/json' },
        });        
        if(response.data.success) {
          const serviceCalls = Array.isArray(response.data.success) ? response.data.success : []; 
          setFilterItems(serviceCalls);
          setFetchItems(serviceCalls);
          // setFilterStatus('All')
        }
        else {
          toast.info("Found no calls for "+techName, { position: "top-center" });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please login again.", {
          position: "top-center"
          });
          navigate('/login');
        } else if (error.response && error.response.status === 403) {
          toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
          position: "top-center"
          });
        }
      } finally {
        setLoading(false);
      }
    
  }

  const handleRemoveTech = async (addition_tech_id) => {
  setLoading(true);
  const bodyAddData = {
    status: "removeAdditionalTech",
    addition_tech_id: addition_tech_id,
  };

  try {
    const response = await axios.post(`${apiUrl}/service_crud.php`, bodyAddData, {
      headers: { 'Content-Type': 'application/json' }
    });
    if (response.data[0] === 'success') {
      setSelectedService(prevService => ({
        ...prevService,
        add_tech: prevService.add_tech.filter(tech => tech.id !== addition_tech_id), // Remove the technician from the array
      }));
      window.location.reload();
      toast.success('Tech removed!', { position: 'top-center' });
    } else {
      toast.info("Failed to remove tech", { position: "top-center" });
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", { position: "top-center" });
      navigate('/login');
    } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! You don't have access to this area. Speak to the CFO.", { position: 'top-center' });
    }
  } finally {
    setLoading(false);
  }
  };

  const handleFilter = async () => {
    // Ensure date range is provided
    if (!dateFrom || !dateTo) {
      toast.error("Please provide both start and end dates.", { position: "top-center" });
      return;
    }

    setLoading(true);
    const bodyData = {
        status: 'getFilter',
        date_from: dateFrom,
        date_to: dateTo,
        tech: filterEmployee,
    }
    try {
      // Fetch filtered data from the server
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      
      const filteredServiceCalls = Array.isArray(response.data) ? response.data : [];
      if (filteredServiceCalls.length === 0) {
        toast.info("No service calls found for the selected criteria.", { position: "top-center" });
      }
      setFilterItems(filteredServiceCalls);
    } catch (error) {
      toast.error("Error fetching filtered data.", { position: "top-center" });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteServiceCall = async (id) => {
    const bodyData = {
      status: "delete",
      id: id,
    };

    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data[0] === 'success') {
        // Filter out the deleted service call from the list
        setFilterItems((prevItems) =>
          prevItems.filter((item) => item.id !== id) // Remove the deleted service call
        );
        toast.success('Service call successfully deleted!', {
          position: 'top-center',
        });
      } else {
        toast.info("Failed to delete the service call.", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVoidServiceCall = async (id) => {
    const bodyData = {
      status: "voidServiceCall",
      id: id,
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data[0] === 'success') {
          setFilterItems(prevItems =>
            prevItems.map(item =>
              item.id === id ? { ...item, STATUS:'VOID' } : item // Set TECH to full name
            )
          );
        toast.success('Service call successfully voided!', {
          position: 'top-center',
        });
      } else {
        toast.info("Failed to void the service call.", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClearAssigned = async (id) => {
    const bodyData = {
      status: "clearAssigned",
      id: id,
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.data[0] === 'success') {
          setFilterItems(prevItems =>
            prevItems.map(item =>
              item.id === id ? { ...item, TECH:'', STATUS: 'PENDING' } : item // Set TECH to full name
            )
          );
          setFetchItems(prevItems =>
            prevItems.map(item =>
              item.id === id ? { ...item, TECH:'', STATUS: 'PENDING' } : item // Set TECH to full name
            )
          );
        toast.success('Service call successfully cleared!', {
          position: 'top-center',
        });
      } else {
        toast.info("Failed to clear the service call.", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleReschedule = async (id) => {
    const bodyData = {
      status: "reschedule",
      id: id,
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });    
      if (response.data[0] === 'success') {
          setFilterItems(prevItems =>
            prevItems.map(item =>
              item.id === id ? { ...item, TECH:'', STATUS: 'COMPLETED', NOTES: `Main call re-assgined to log:${response.data[1]}`} : item // Set TECH to full name
            )
          );
        toast.success('Service call successfully rescheduled!', {
          position: 'top-center',
        });
      } else {
        toast.info("Failed to re-schedule the service call.", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCompleteStatus = async (id) => {
    const bodyData = {
      status: "completeStatus",
      id: id,
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });    
      if (response.data[0] === 'success') {
          setFilterItems(prevItems =>
            prevItems.map(item =>
              item.id === id ? { ...item, STATUS: 'COMPLETED'} : item // Set TECH to full name
            )
          );
        toast.success('Service call status to completed!', {
          position: 'top-center',
        });
      } else {
        toast.info("Failed to change status of service call.", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCheckedReady = async (id) => {
    const bodyData = {
      status: "checkedReady",
      id: id,
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });    
      if (response.data[0] === 'success') {
          setFilterItems(prevItems =>
            prevItems.map(item =>
              item.id === id ? { ...item, INVOICE: 'READY'} : item // Set TECH to full name
            )
          );
        toast.success('Service call ready for invoice!', {
          position: 'top-center',
        });
      } else {
        toast.info("Failed to change invoice status.", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCharge = async (id, type) => {
    const bodyData = {
      status: "handleCharge",
      id: id,
      type: type
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });    
      if (response.data[0] === 'success') {
          setFilterItems(prevItems =>
            prevItems.map(item =>
              item.id === id ? { ...item, TYPE: type} : item // Set TECH to full name
            )
          );
        toast.success('Service call - '+type+'!!', {
          position: 'top-center',
        });
      } else {
        toast.info("Failed to change type.", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleIncompleteStatus = async (id, type) => {
    const bodyData = {
      status: "incompleteStatus",
      id: id
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });    
      if (response.data[0] === 'success') {
          setFilterItems(prevItems =>
            prevItems.map(item =>
              item.id === id ? { ...item, STATUS: "INCOMPLETE"} : item 
            )
          );
        toast.success('Service call status changed to incomplete', {
          position: 'top-center',
        });
      } else {
        toast.info("Failed to change to status.", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center",
        });
      }
    } finally {
      setLoading(false);
    }
  };

   // Handle input changes when adding a new reimbursement
  // const handleNewInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setNewTechAdding((prev) => ({ ...prev, [name]: value }));
  // }; 

  const handleAdditionalTech = async (employeeId, employeeName, serviceCall) => { 
    // setLoading(true);
    const isAlreadyAdded = additionTech.some((tech) => parseInt(tech.tech_id) === parseInt(employeeId)); // Convert both to numbers
    if (isAlreadyAdded) {
      toast.info('This technician has already been added.', { position: 'top-center' });
      setLoading(false);
      return; // Prevent adding duplicate tech
    }
    if ( employeeName === serviceCall.TECH) {
      toast.info(serviceCall.TECH+' is the lead tech on the call.', { position: 'top-center' });
      setLoading(false);
      return; // Prevent adding duplicate tech
    }
    const bodyData = {
      status: "additionalTech",
      tech_id: employeeId,
      id: serviceCall.id,
      tech_name: employeeName
    };
    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data[0] === 'success') {
         window.location.reload();
        // const newTech = Array.isArray(response.data[1]) ? response.data[1] : [response.data[1]];
        // setAdditionTech((prevTech) => [...prevTech, ...newTech]);
        toast.success('Tech added!', {
          position: 'top-center',
        });
      } else {
        toast.info("Failed to add tech", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center"
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center"
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const getLinksForJob = (object) => {
      const links = [
        { name: 'Edit Call', click: () => navigate(`/service_new/${object.id}`) },
        { name: 'Void Call', click: () => handleVoidServiceCall(object.id), key: `void-${object.id}` },
        { name: 'Upload File', click: () => {setIsModalFileGenOpen(true); setSelectGenFile(object.id); }, key: `void-${object.id}` },
      ];

      if(object.STATUS === 'COMPLETED')
      {
        //edit service ticket option
        if(object.INVOICE === ''){
          links.push({
            name: 'Checked(Ready)',
            click: () => handleCheckedReady(object.id),
            key: `invoice-${object.id}`
          });          
          
          links.push({
            name: 'Incomplete',
            click: () => handleIncompleteStatus(object.id),
            key: `incomplete-${object.id}`
          });
        }

        if (object.INVOICE === 'READY') {
          links.push({
            name: 'Upload Invoice',
            click: () => {
              setIsModalFileOpen(true);  // Open the modal when clicked
              setSelectInvoiceFile(object.id);  // Set the selected invoice genFile
            },
            key: `uploadfile-${object.id}`
          });
        }

        links.push({
          name: object.TYPE === 'NO CHARGE' ? 'Charge' : 'No Charge',
          click: () => handleCharge(object.id, object.TYPE === 'NO CHARGE' ? 'CHARGE' : 'NO CHARGE'),
          key: `type-${object.id}`
        });        
      }
      else
      {
        if (object.STATUS === 'ASSIGNED') {
          links.push({
            name: 'Clear Assigned',
            click: () => handleClearAssigned(object.id),
            key: `clear-${object.id}`
          });
          links.push({
            name: 'Add Helper',
            click: () => {
              setSelectedServiceCall(object); // Set the currently selected service call
              setIsModalOpen(true);   // Open the modal
              setSelectedService(object) //selecting the ID of servicecall
            },
            key: `helper-${object.id}`
          });
        }
        if (object.STATUS === 'ACTIVATED') {
          links.push({
            name: 'Clear Assigned',
            click: () => handleClearAssigned(object.id),
            key: `clear_assigned-${object.id}`
          });
          links.push({
            name: 'Add Helper',
            click: () => {
              setSelectedServiceCall(object); // Set the currently selected service call
              setIsModalOpen(true);  // Open the modal
              setSelectedService(object) //selecting the ID of servicecall
            },
            key: `helper-${object.id}`
          });

        }
        if (object.STATUS === 'INCOMPLETE') {
          links.push({
            name: 'Re-Schedule',
            click: () => handleReschedule(object.id),
            key: `reschedule-${object.id}`
          });
          links.push({
            name: 'Completed',
            click: () => handleCompleteStatus(object.id),
            key: `complete-${object.id}`
          });
        }
        if (object.STATUS === 'PENDING') {
          links.push({
            name: 'Assign',
            click: () => {
              setSelectedService(object) //selecting the ID of servicecall
              setIsModalOpenLead(true)
            },
            key: `assign-${object.id}`
          });
        }
        if (object.STATUS !== 'ASSIGNED' && object.STATUS !== 'INCOMPLETE' || 
            object.STATUS !== 'ACTIVATED' && object.STATUS !== 'INCOMPLETE') {
          links.push({
            name: 'Delete Call',
            click: () => handleDeleteServiceCall(object.id), 
            key: `delete-${object.id}` 
          });
        }
      }

      return links
  };

  const handleTechChange = async (employeeId, employeeName, serviceCallId) => {
    const bodyData = {
      status: "assigned",
      tech_id: employeeId,
      id: serviceCallId,  // Pass serviceCallId directly here if it's just the ID
      tech: employeeName
    };

    setLoading(true);

    try {
      const response = await axios.post(`${apiUrl}/service_crud.php`, bodyData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data[0] === 'success') {
        setFilterItems(prevItems =>
          prevItems.map(item =>
            item.id === serviceCallId ? { ...item, TECH: employeeName, STATUS: 'ASSIGNED' } : item // Match by serviceCallId
          )
        );
        setFetchItems(prevItems =>
          prevItems.map(item =>
            item.id === serviceCallId ? { ...item, TECH: employeeName, STATUS: 'ASSIGNED' } : item // Match by serviceCallId
          )
        );
        setIsModalOpenLead(false);
        toast.success('Service call successfully assigned!', {
          position: 'top-center',
        });
      } else {
        toast.info("Failed to assign call.", { position: "top-center" });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please login again.", {
          position: "top-center",
        });
        navigate('/login');
      } else if (error.response && error.response.status === 403) {
        toast.error("Oops!! You don't have access to this area. Speak to the CFO.", {
          position: "top-center",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) {
      toast.error("No file selected.", { position: 'top-center' });
      setFile(null);
      return;
    }

    const validFileTypes = ['application/pdf'];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    if (!validFileTypes.includes(selectedFile.type)) {
      toast.error("Invalid file type. Only PDF is allowed.", { position: 'top-center' });
      setFile(null);
      return;
    }

    if (selectedFile.size > maxSize) {
      toast.error("File size exceeds 2MB.", { position: 'top-center' });
      setFile(null);
      return;
    }

    setFile(selectedFile);
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return;
    }
    
    const formData = new FormData();
    formData.append('status', 'submit_invoice_file');
    formData.append('id', selectInvoiceFile); // Include the accounting vendor ID
    formData.append('file', file);

    try {
      const response = await axios.post(`${apiUrl}/service_file_invoice.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });

        // Update the state with the new file information
        setFilterItems(prevItems =>
            prevItems.map(item =>
              item.id === selectInvoiceFile ? { ...item, INVOICE: response.data[0] } : item // Set TECH to full name
            )
        );        

        setFile(null);
        setIsModalFileOpen(false);
      } else {
        toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("An error occurred during file upload.", { position: 'top-center' });
      //console.error('Error uploading file', error);
    }
  };

  const handleGenUploadFile = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before uploading.", { position: 'top-center' });
      return;
    }
    
    const formData = new FormData();
    formData.append('status', 'submit_gen_file');
    formData.append('id', selectGenFile); // Include the accounting vendor ID
    formData.append('file', file);
    
    try {
      const response = await axios.post(`${apiUrl}/service_file_gen.php`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (response.data.success) {
        toast.info('File uploaded successfully!', { position: 'top-center' });

        // Update the state with the new file information
        setFilterItems(prevItems =>
            prevItems.map(item =>
              item.id === selectGenFile ? { ...item, file_name: response.data[0] } : item // Set TECH to full name
            )
        );

        setFile(null);
        setIsModalFileGenOpen(false);
      } else {
        toast.error(response.data.error || "Error uploading file", { position: 'top-center' });
      }
    } catch (error) {
      toast.error("An error occurred during file upload.", { position: 'top-center' });
      //console.error('Error uploading file', error);
    }
  };

  const handleSearchClick = async () => {
    setLoading(true);
    const bodyData = {
      status: 'searchCall',
      data: { search: searchTerm }
    };
    try {
      const response = await axios.post(api, bodyData, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (response.data && Array.isArray(response.data)) {
        setFilterItems(response.data);
      } else {
        setFilterItems([]);
        toast.error('Not found: ' + searchTerm, { position: 'top-center' });
      }
    } catch (error) {     
      if (error.response && error.response.status === 401) {
      toast.error("Session expired. Please login again.", {
      position: "top-center"
      });
      navigate('/login');
      } else if (error.response && error.response.status === 403) {
      toast.error("Oops!! you don't have access to this area. Speak to the CFO.", {
      position: "top-center"
      });
      }
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 py-10">
      <HeadingCp label={"Service Dashboard"}/>
      <LegendCP color="fill-red-300" label="Incomplete" /> 
      <LegendCP color="fill-green-400" label="Invoice Ready" /> 
      <LegendCP color="fill-orange-600" label="No Charge" /> 
      <LegendCP color="fill-yellow-300" label="Assigned" /> 
      <LegendCP color="fill-blue-300" label="Pending" /> 

      <div className="mt-12 flex items-center gap-4 max-w-6xl w-full mx-auto -ml-8">
        <NavLink to="/service_new/new">
          <button
            type="button"
            className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
              shadow-sm text-white bg-orange-400 hover:bg-orange-700 focus:outline-none 
              focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            New Service Call +
          </button>
        </NavLink>
        <NavLink to={`/service_cal`} >
          <button
          type="button"
          className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
            shadow-sm text-white bg-green-400 hover:bg-green-700 focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          Cal View
        </button>
        </NavLink>
        <select
          className="border border-gray-300 rounded-md px-8 py-2"
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}>
          <option value="ALL">All</option>
          <option value="ASSIGNED">Assigned</option>
          <option value="PENDING">Pending</option>
          <option value="VOID">Void</option>
          <option value="COMPLETED">Completed</option>
          <option value="READY">Ready</option>
          <option value="NOT READY">Not Ready</option>
          <option value="INCOMPLETE">Incomplete</option>
        </select>

        <input
          type="date"
          className="border border-gray-300 rounded-md px-2 py-2"
          value={dateFrom}
          onChange={(e) => setDateFrom(e.target.value)}/>
        
        <span>to</span>
        
        <input
          type="date"
          className="border border-gray-300 rounded-md px-2 py-2"
          value={dateTo}
          onChange={(e) => setDateTo(e.target.value)}/>

        <select className="border border-gray-300 rounded-md px-6 py-2">
          <option value="">Select Employee</option>
          {employees && employees.length > 0 ? (
            employees.map((employee) => (
              <option key={employee.id} value={employee.id}>
                {employee.first_name} {employee.last_name}
              </option>
            ))
          ) : (
            <option value="" disabled>
              No employees found
            </option>
          )}
        </select>

        <button
          type="button"
          onClick={handleFilter}
          className="px-4 py-2 border border-transparent text-sm font-medium rounded-md 
            shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none 
            focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Filter
        </button> 
      </div>

      <div className="mt-4 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <LoadingSpinner>Loading...</LoadingSpinner>
            ) : (
              <div className="shadow ring-1 ring-black ring-opacity-5">

                <div className="flex-grow mb-3">
                  <SearchBarCP 
                    search={searchTerm} 
                    handleChange={(e) => setSearchTerm(e.target.value)}
                    onClick={handleSearchClick}
                  />
                </div>

                <table className="min-w-full max-w-6xl table-auto border-separate border-spacing-0">
                  <thead className="top-0 z-30 bg-white">
                    <tr>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                        MenuId
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                        User|Time-Stamp
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">
                        Job Site | Customer
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">
                        Condition
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                        Called By
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                        Tech
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                        File
                      </th>
                      <th className="top-0 z-30 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                        Notes
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterItems.map((object, objectIdx) => (
                      <tr key={object.id}    className={classNames(
                        object.STATUS === 'INCOMPLETE' 
                          ? 'bg-red-100'  // Light red for INCOMPLETE
                          : object.STATUS === 'PENDING' 
                            ? 'bg-blue-100'  // Light blue for PENDING
                            : object.INVOICE === 'READY' && object.TYPE !== 'CHARGE' || 
                              (object.INVOICE !== '' && object.TYPE !== 'NO CHARGE')
                              ? 'bg-green-100'  // Light green for READY
                              : object.TYPE === 'NO CHARGE' 
                                ? 'bg-orange-100'  // Light orange for NO CHARGE
                                : object.STATUS === 'ASSIGNED' 
                                  ? 'bg-yellow-100'  // Light yellow for ASSIGNED
                                  : objectIdx % 2 === 0 
                                    ? 'bg-white'  // Default alternating colors for even rows
                                    : 'bg-gray-100',  // Default alternating colors for odd rows

                                      'divide-x divide-gray-300 divide-y' // Always apply these classes
                                    )}>
                        <td
                          className={classNames(
                            objectIdx !== filterItems.length - 1 ? 'border-b border-gray-200' : '',
                            'py-2 pl-4 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-2',
                            object.STATUS === 'VOID' ? 'line-through text-red-500' : '' // Red line-through for VOID
                          )}
                        >
                          <span className='text-xs text-green-700 font-bold'>
                            {object.STATUS === 'ACTIVATED' ? 'Activated' : ''}
                          </span>
                          <SubMenu label={object.id} idItem={object.id} links={getLinksForJob(object)} />
                        </td>

                        <td className={classNames(
                            objectIdx !== filterItems.length - 1 ? 'border-b border-gray-200' : '',
                            'py-2 pl-2 px-4 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-2',
                            object.STATUS === 'VOID' ? 'line-through text-red-500' : '' // Red line-through for VOID
                          )}
                        >
                          <div className="flex flex-col">
                            <span className="text-gray-800 text-xs">
                              <DateFormat date={object.DATE} /> | {object.TIME_LOG}
                            </span>
                            <span className="text-gray-400">{object.USER}</span>
                          </div>
                        </td>

                        {/* jobsite | customer */}
                        <td className={classNames('px-4', object.STATUS === 'VOID' ? 'line-through text-red-500' : '')}>
                          <div className="flex flex-col">
                            <span className="text-gray-800 text-sm">{object.ADDRESS}</span>
                            <span className="text-gray-400 text-xs">{object.CUSTOMER}</span>
                          </div>
                        </td>
                        
                        {/* trouble */}
                        <td className={classNames('px-4 py-2', object.STATUS === 'VOID' ? 'line-through text-red-500' : '')}>
                          <span className="text-gray-800 text-xs">{object.TROUBLE}</span>
                        </td>
                        
                        {/* phone */}
                        <td className={classNames('px-4', object.STATUS === 'VOID' ? 'line-through text-red-500' : '')}>
                          <div className="flex flex-col">
                            <span className="text-gray-800 text-sm">{object.CALLED_BY}</span>
                            <span className="text-gray-600 text-xs">
                              <PhoneNumber number={object.PHONE} />
                            </span>
                          </div>
                        </td>
                        
                        {/* Tech */}
                        <td className={classNames(object.STATUS === 'VOID' ? 'line-through text-red-500' : '', 'px-3')}>
                          <span className="text-gray-800 text-xs">
                            {object.TECH ? (
                              <>
                                <span className="text-orange-800 hover:cursor-pointer hover:text-blue-800" onClick={() => handleTechFilter (object.TECH)}>
                                  {object.TECH}
                                </span><br/>
                                
                                {/* Check if there are any additional techs */}
                                {object.add_tech && object.add_tech.length > 0 && (
                                  <>
                                    {object.add_tech.map((additionalTech, index) => (
                                      <span key={index} className="text-gray-800">| {additionalTech.tech_name} 
                                      </span>
                                    ))}
                                  </>
                                )}
                              </>
                            ) : (
                              'no assignment'
                            )}
                          </span>
                        </td>
                        
                        {/* files */}
                        <td className={classNames('px-4', object.STATUS === 'VOID' ? 'line-through text-red-500' : '')}>
                          <span className='text-green-700'><ServiceInvoiceFileLocation expFile={object.INVOICE} where={'service_file'} /></span>
                          <span className='text-gray-700'><ServiceGenFileLocation expFile={object.file_name} where={'service_file'} /></span>
                          <span className='text-gray-700 p-1'>
                            {(object.SERVICE_TICKET === null || object.SERVICE_TICKET === '') ? (
                              ''
                            ) : (
                              <NavLink 
                                to={`/service_ticket/${object.id}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                              >
                                <DocumentCheckIcon className="w-5" />
                              </NavLink>
                            )}
                          </span>
                        </td>
                        
                        {/* Notes */}
                        <td className={classNames('px-2', object.STATUS === 'VOID' ? 'line-through text-red-500' : '')}>
                          <span className="text-gray-800 text-xs">
                            {/* {object.NOTES} */}
                            {object.NOTES && 
                              <Truncate text={object.NOTES} handleNotesView={() => handleNotesView(object.id)} />
                            }
                            {notesIdOpen === object.id && (
                              <ModalWindow job={object.NOTES} closeNotes={() => setNotesIdOpen(null)} activeButton={true} />
                            )}
                          </span>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {/* file invoice upload */}
            {isModalFileOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50">
                <div className="bg-white p-8 rounded-md shadow-md min-w-[500px]">
                  <h2 className="text-xl font-semibold mb-4">Upload Invoice File</h2>
                  <form onSubmit={handleUploadFile}>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Select a PDF file (Max size 2MB)</label>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleFileChange}
                        className="mt-2"
                      />
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => setIsModalFileOpen(false)}
                        className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                      >
                        Upload
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* file upload */}
            {isModalFileGenOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50">
                <div className="bg-white p-8 rounded-md shadow-md min-w-[500px]">
                  <h2 className="text-xl font-semibold mb-4">Upload File</h2>
                  <form onSubmit={handleGenUploadFile}>
                    <div className="mb-4">
                      <label className="block text-sm font-medium text-gray-700">Select a PDF file (Max size 2MB)</label>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={handleFileChange}
                        className="mt-2"
                      />
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => setIsModalFileGenOpen(false)}
                        className="px-4 py-2 mr-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                      >
                        Upload
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}

            {/* Add Tech Modal */} 
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              { selectedService && Array.isArray(selectedService.add_tech) && selectedService.add_tech.length > 0 ? (
                <ul className="list-disc pl-4 mb-3">
                  {selectedService.add_tech.map((tech) => (
                    <li key={tech.id} className="bg-gray-100 py-2 mb-2 rounded flex items-center">
                      <span className="mr-2 hover:cursor-pointer" onClick={() => handleRemoveTech(tech.id)}>
                        <TrashIcon className="h-5 text-red-600" />
                      </span>
                      <span>{tech.tech_name}</span>
                    </li>
                  ))}
                </ul>
              ) : null }
              <h2 className="text-lg font-semibold text-gray-900">Add Helper</h2>
              <select className="border border-gray-300 rounded-md px-6 py-2 w-full"
                  value={addTech.additional_tech || ''}
                  onChange={(e) => {
                    const selectedOption = e.target.options[e.target.selectedIndex];
                    const employeeId = e.target.value;
                    const employeeName = selectedOption.textContent;
                    handleAdditionalTech(employeeId, employeeName, selectedService); 
                  }}
              >
                <option value="">Select Employee</option>
                {employees && employees.length > 0 ? (
                  employees.map((employee) => (
                    <option key={employee.id} className="sm:text-lg" value={employee.id}>
                      {employee.first_name} {employee.last_name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No employees found
                  </option>
                )}
              </select>
            </Modal>

            {/* ADD Lead Tech */} 
            <Modal isOpen={isModalOpenLead} onClose={() => setIsModalOpenLead(false)}>
              <h2 className="text-lg font-semibold text-gray-900">Lead Tech</h2>
              <select className="border border-gray-300 rounded-md px-6 py-2 w-full"
                  value={addTech.additional_tech || ''}
                  onChange={(e) => {
                    const selectedOption = e.target.options[e.target.selectedIndex];
                    const employeeId = e.target.value;
                    const employeeName = selectedOption.textContent;
                    handleTechChange(employeeId, employeeName, selectedService.id); 
                  }}
              >
                <option value="">Select Employee</option>
                {employees && employees.length > 0 ? (
                  employees.map((employee) => (
                    <option key={employee.id} className="sm:text-lg" value={employee.id}>
                      {employee.first_name} {employee.last_name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No employees found
                  </option>
                )}
              </select>
            </Modal>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCallList;
